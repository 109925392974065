import { Controller } from 'react-hook-form';
import { TextField, Box, FormControl, FormHelperText } from '@mui/material';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import AddIcon from '@mui/icons-material/Add';

const CustomSelectAddItem = ({
  name,
  sx,
  children,
  setDialogValue,
  handleAddItem,
  label,
  errors,
  options,
  control,
  setValue,
  setSelectedBrandName = () => {},
  selectedBrandName,
  setCurrentBrandId = () => {},
  setHasValueForCreation = () => {},
  ...params
}) => {
  const filterOpt = createFilterOptions();
  const error = errors ? !!errors[name] : false;

  return (
    <FormControl size="small" sx={sx} error={error}>
      <Controller
        render={({ field }) => {
          const { value } = field;
          return (
            <Autocomplete
              sx={sx}
              name={name}
              value={value || selectedBrandName || ''}
              onBlur={(e) => {
                setValue('brandName', e.target.value);
              }}
              // InputLabelProps={{
              //   shrink: true,
              // }}
              onSelect={(e) => {
                setValue('brandName', e.target.value);
              }}
              onChange={(event, newValue) => {
                localStorage.setItem('currentBrand', JSON.stringify(newValue));
                setCurrentBrandId(newValue?._id);
                setSelectedBrandName(newValue);
              }}
              handleHomeEndKeys
              freeSolo
              selectOnFocus
              filterOptions={(option, params) => {
                const filtered = filterOpt(option, params);
                const filterAddButton = option.filter((item) => item && item.brandName.includes('Add new brand'));
                if (params.inputValue === '' && !filterAddButton.length) {
                  setHasValueForCreation(params.inputValue);
                  filtered.unshift({
                    brandName: `Add new  brand`,
                  });
                } else if (params.inputValue !== '') {
                  setHasValueForCreation(params.inputValue);
                  filtered.unshift({
                    inputValue: params.inputValue,
                    brandName: `Add "${params.inputValue}" as a new  brand'`,
                    key: params.inputValue,
                  });
                }
                return filtered;
              }}
              id="free-solo-dialog-demo"
              options={options}
              getOptionLabel={(option) => {
                if (option) {
                  if (option?.brandName != 'Add new  brand') {
                    if (typeof option === 'string') {
                      return option;
                    }
                    if (option?.inputValue) {
                      return option.inputValue;
                    }
                    if (
                      !option?.brandName.includes('Add new  brand') ||
                      !option?.brandName.includes(`Add "${option.inputValue}" as `)
                    ) {
                      return option.brandName;
                    }
                  } else {
                    return '';
                  }
                } else {
                  return '';
                }
              }}
              renderOption={(props, option) => {
                if (option) {
                  if (
                    option?.brandName.includes('Add new  brand') ||
                    option?.brandName.includes(`Add "${option.inputValue}" as `)
                  ) {
                    return (
                      <Box
                        component="li"
                        key={props.id}
                        sx={{
                          width: '100%',
                          height: '40px',
                          zIndex: 20,
                          textTransform: 'none',
                          justifyContent: 'left',
                          padding: '6px 16px',
                          border: 'none',
                          textAlign: 'left',
                          color: '#2D7FF9',
                          fontSize: '16px',
                          background: 'none',
                          '&:focus': {
                            outline: 'none',
                          },
                          '&:hover': {
                            color: '#1f58ae',
                          },
                          '&.Mui-selected': {
                            '&:hover': {
                              backgroundColor: 'transparent',
                            },
                            background: 'none',
                          },
                        }}
                        {...props}
                        onClick={() => handleAddItem(option.inputValue)}
                      >
                        <AddIcon fontSize="16px" />
                        {option.brandName}
                      </Box>
                    );
                  }

                  const propsData = {
                    'aria-disabled': props['aria-disabled'],
                    'aria-selected': props['aria-selected'],
                    className: props.className,
                    'data-option-index': props['data-option-index'],
                    id: props.id,
                    onClick: props.onClick,
                    onMouseOver: props.onMouseOver,
                    onTouchStart: props.onTouchStart,
                    role: props.role,
                    tabIndex: props.tabIndex,
                    key: props.id,
                  };
                  return <li {...propsData}>{option?.brandName}</li>;
                } else {
                  return;
                }
              }}
              renderInput={(params) => (
                <TextField
                  error={error}
                  {...params}
                  size="small"
                  label={label}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              )}
            />
          );
        }}
        sx={sx}
        name={name}
        control={control}
        {...params}
      />
      <FormHelperText>{errors && errors[name] ? errors[name].message : ''}</FormHelperText>
    </FormControl>
  );
};

export default CustomSelectAddItem;
