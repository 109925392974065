import { useState } from 'react';

export const useCopy = (text) => {
  const [showCopyMessage, setShowCopyMessage] = useState(false);
  const [copiedRowId, setCopiedRowId] = useState(null);

  const handleCopy = (copyText = '', rowId = null) => {
    setShowCopyMessage(true);
    setCopiedRowId(rowId);
    navigator?.clipboard?.writeText(copyText ? copyText : `${text}`);
    setTimeout(() => {
      setShowCopyMessage(false);
    }, 2000);
  };

  return {
    handleCopy,
    showCopyMessage,
    copiedRowId,
  };
};
