import { useEffect, useState } from 'react';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { yupResolver } from '@hookform/resolvers/yup';
import { connectProviderLocationEzCater } from 'redux/actions/providers';

export const initialDataModel = {
  username: '',
  password: '',
  authKey: ''
};

const formSchema = yup.object().shape({
  username: yup.string().required('This field is required'),
  password: yup.string().required('This field is required'),
  authKey: yup.string().required('This field is required'),
});

const useEzCaterConnect = (open, handleClose, providerSRV, setSubmitted, locationsModalVisible) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const visiblePassword = () => setShowPassword((prevState) => !prevState);

  const formMethods = useForm({
    mode: 'all',
    defaultValues: initialDataModel,
    resolver: yupResolver(formSchema),
  });

  const handleSubmit = (values) => {
    setLoading(true);
    try {
      setSubmitted(true);
      dispatch(
        connectProviderLocationEzCater({
          readonly: false,
          provider: providerSRV,
          data: values,
        })
      );
      dispatch({ type: 'TARGET_PROVIDER_CONNECT', payload: providerSRV });
      dispatch({ type: 'PROVIDER_CONNECTION_STARTED', payload: true });
      dispatch({ type: 'PROVIDER_CONNECT_CONVENTIONAL_ERROR', payload: '' });
    } catch (error) {
      setSubmitted(false);
      console.error('Error connecting provider location:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!open) {
      formMethods.reset(initialDataModel);
      dispatch({ type: 'PROVIDER_CONNECT_CONVENTIONAL_ERROR', payload: '' });
    }
  }, [open]);

  useEffect(() => {
    if (locationsModalVisible) {
      handleClose();
    }
  }, [locationsModalVisible]);

  return {
    handleSubmit,
    loading,
    formMethods,
    showPassword,
    visiblePassword
  }
}

export default useEzCaterConnect;