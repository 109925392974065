import { useState } from 'react';
import * as yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { PROVIDER_MODE } from 'pages/Providers/Providers';
import { selectCurrentBusiness } from 'components/Providers/ProviderList';
import { connectStoreProvider, getProvidersStatuses } from 'redux/actions/providers';
export const initialDataModel = {
  storeId: '',
  previewUrl: '',
  username: '',
  password: '',
};

const formSchema = yup.object().shape({
  storeId: yup.string().required('This field is required'),
  previewUrl: yup.string().url('Invalid URL').required('This field is required'),
  username: yup.string().required('This field is required'),
  password: yup.string().required('This field is required'),
});

const useProviderConnectReadOnly = (mode, handleClose, providerSRV) => {
  const dispatch = useDispatch();
  const currentBusiness = useSelector(selectCurrentBusiness);
  const [loading, setLoading] = useState(false);

  const formMethods = useForm({
    mode: 'all',
    defaultValues: initialDataModel,
    resolver: yupResolver(formSchema),
  });

  const handleSubmit = (values) => {
    setLoading(true);
    const dataObj = {
      readonly: Boolean(mode === PROVIDER_MODE.READONLY),
      provider: providerSRV,
      businessUuid: currentBusiness?.uuid || '',
      data: {
        username: values.username,
        password: values.password,
        previewUrl: values.previewUrl,
        storeId: values.storeId,
      },
    };
    return dispatch(connectStoreProvider(dataObj))
      .then(() => {
        dispatch(getProvidersStatuses());
      })
      .catch(() => setLoading(true))
      .finally(() => {
        setLoading(true);
        handleClose();
      });
  };
  return {
    formMethods,
    handleSubmit,
    loading,
  };
};

export default useProviderConnectReadOnly;
