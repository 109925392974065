import { Box, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import CustomTextInput from 'components/Forms/CustomTextInput';
import LabelRequired from 'components/FormFields/LabelRequired';
import { PADDING_MODAL_ACTION_LEFT, PADDING_MODAL_ACTION_RIGHT } from 'pages/TableOrdering/config';


const CreateAndEditTag = () => {
  const { t } = useTranslation();

  return (
    <Box sx={{ mt: 1 }}>
      <Grid container spacing={2}>
        <Grid item md={12} xs={12} lg={12} xl={12}>
          <Box
            sx={{
            ...PADDING_MODAL_ACTION_LEFT,
            ...PADDING_MODAL_ACTION_RIGHT
           }}
          >
            <CustomTextInput
              sx={{ width: '100%', height: '74px' }}
              name="tagName"
              label={<LabelRequired>{t('Name')}</LabelRequired>}
            />
          </Box>
        </Grid>
      </Grid>
    </Box>
  )
}

export default CreateAndEditTag;