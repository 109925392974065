import { useEffect, useMemo } from 'react';
import CheckActive from 'assets/icons/checkbox_active.svg';
import CheckInactive from 'assets/icons/checkbox_inactive.svg';
import EditIcon from '@mui/icons-material/Edit';
import { Typography, Tooltip } from '@mui/material';
import { getCoupons } from 'redux/actions/coupons';
import DeleteIcon from 'assets/icons/delete.svg';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import i18n from 'i18n';
import { MarketingTypes } from 'components/Coupons/types';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import { useCopy } from './useCopy';
import { StyledButton } from 'components/Providers/styles';
import { selectCurrentBusiness } from 'components/Providers/ProviderList';
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import { CopiedMessageShortLink, StyledTitleShortLink } from 'components/Order/OrderConfirmModal/styled';

const convertShortLInk = (baseUrl, alias, coupon) => {
  if (baseUrl) return `${baseUrl}/${alias}?oco_coupon=${coupon}`;

  return `${process.env.REACT_APP_FOOD2_URL}/${alias}?oco_coupon=${coupon}`
}

export const useCouponsData = (isPopular = false) => {
  const dispatch = useDispatch();
  const { activeCoupons, expiredCoupons, loading } = useSelector(({ coupons }) => coupons);

  useEffect(() => {
    if (isPopular) {
      dispatch(getCoupons({ active: true }));
    } else {
      dispatch(getCoupons({ active: true }));
      dispatch(getCoupons({ active: false }));
    }
  }, [isPopular]);

  return {
    activeCoupons,
    expiredCoupons,
    isLoading: loading,
  };
};

export const useCouponsActiveTableColumns = (handleDelete, handleEdit) => {
  const { t } = useTranslation();
  const currentBusiness = useSelector(selectCurrentBusiness);
  const { handleCopy, showCopyMessage, copiedRowId } = useCopy();

  const genericColumns = [
    {
      field: 'couponName',
      headerName: t('website_settings.coupone_name'),
      flex: 1,
      sortable: false,
      filterable: false,
      editable: false,
    },
    {
      field: 'couponTitle',
      headerName: t('website_settings.coupone_title'),
      flex: 1,
      sortable: false,
      filterable: false,
      editable: false,
    },
    {
      field: 'couponCode',
      headerName: t('website_settings.coupon_code'),
      flex: 1,
      sortable: false,
      filterable: false,
      editable: false,
    },
    {
      field: 'amount',
      headerName: t('website_settings.amount_percent'),
      flex: 1,
      sortable: false,
      editable: false,
      filterable: false,
      renderCell: ({ row, value }) => {
        return row.percent ? (
          <Typography sx={{ textAlign: 'right', width: '100%' }}>{`${row.percent}%`}</Typography>
        ) : (
          <Typography sx={{ textAlign: 'right', width: '100%' }}>{`$${row.amount}`}</Typography>
        );
      },
    },
    {
      field: 'startDate',
      headerName: t('website_settings.start_date_'),
      editable: false,
      flex: 1,
      sortable: false,
      filterable: false,
      renderCell: ({ value }) => {
        return moment(value).format('MM/DD/YYYY');
      },
    },
    {
      field: 'endDate',
      headerName: t('website_settings.end_date_'),
      editable: false,
      flex: 1,
      sortable: false,
      filterable: false,
      renderCell: ({ value }) => {
        return moment(value).format('MM/DD/YYYY');
      },
    },
    {
      field: 'useForMarketing',
      headerName: t('menu.available_marketing'),
      editable: false,
      flex: 1,
      sortable: false,
      filterable: false,
      renderCell: ({ row }) => {
        if (row?.type && row?.type === MarketingTypes.SYSTEM) {
          return <img src={CheckInactive} alt="ONLINE_ICON" />;
        } else if (row?.type && row?.type === MarketingTypes.MARKETING) {
          return <img src={CheckActive} alt="ONLINE_ICON" />;
        }
      },
    },
    {
      field: 'shortLink',
      headerName: t('Website link'),
      editable: false,
      flex: 1,
      minWidth: 150,
      sortable: false,
      filterable: false,
      renderCell: ({ row }) => {
        const baseUrl = currentBusiness?.settings?.baseUrl || '';
        const alisa = currentBusiness?.settings?.url || '';
        const currentUrl = convertShortLInk(baseUrl, alisa, row?.couponCode);

          return (
            <Box sx={{ display: 'flex', gap: '2px', width: '100%', position: 'relative' }}>
              <Tooltip title={currentUrl} placement={'top'}>
                <StyledTitleShortLink>{currentUrl || ''}</StyledTitleShortLink>
              </Tooltip>
              <StyledButton
                sx={{ width: '20px' }}
                loading={false}
                variant="text"
                startIcon={
                  <ContentCopyOutlinedIcon
                    style={{
                      color: '#929398',
                      width: '24px',
                      height: '24px'
                    }}
                  />
                }
                onClick={() => handleCopy(currentUrl, row?.id)}
              >
              </StyledButton>
              {(showCopyMessage && row?.id === copiedRowId) && (
                <CopiedMessageShortLink className={`message-container ${showCopyMessage ? 'show fade-in' : ''}`}>
                  <Typography color="white" variant="body2" className="message-text">
                    {t('Copied')}
                  </Typography>
                </CopiedMessageShortLink>
              )}
            </Box>
          );
      },
    },
    {
      field: 'freeDelivery',
      headerName: t('website_settings.free_delivery'),
      editable: false,
      flex: 1,
      sortable: false,
      filterable: false,
      renderCell: ({ row }) => {
        return <img src={row.freeDelivery ? CheckActive : CheckInactive} alt="ONLINE_ICON" />;
      },
    },
  ];
  const activeCoupons = useMemo(
    () => [
      ...genericColumns,
      {
        field: 'Tools',
        headerName: t('settings.tools'),
        type: 'actions',
        flex: 1,
        renderCell: (params) => {
          const isMultipleIds = params?.row?.businessIds?.length > 1 || params?.row?.businessUuids?.length > 1;
          return (
            <Box sx={{ display: 'flex' }}>
              <IconButton
                aria-label="delete"
                disabled={isMultipleIds}
                onClick={() => handleEdit(params?.row)}
              >
                <EditIcon />
              </IconButton>
              <IconButton
                disabled={isMultipleIds}
                aria-label="delete"
                onClick={() => handleDelete(params?.row)}
              >
                <img
                  style={{ opacity: isMultipleIds ? .5 : 1  }}
                  src={DeleteIcon} alt="delete icon"
                />
              </IconButton>
            </Box>
          );
        },
      },
    ],
    [i18n.language, showCopyMessage, copiedRowId]
  );

  return activeCoupons;
};

export const useCouponsInactiveTableColumns = (handleDelete, handleEdit) => {
  const { t } = useTranslation();
  const currentBusiness = useSelector(selectCurrentBusiness);
  const { handleCopy, showCopyMessage, copiedRowId } = useCopy();

  const genericColumns = [
    {
      field: 'couponName',
      headerName: t('website_settings.coupone_name'),
      flex: 1,
      sortable: false,
      filterable: false,
      editable: false,
    },
    {
      field: 'couponTitle',
      headerName: t('website_settings.coupone_title'),
      flex: 1,
      sortable: false,
      filterable: false,
      editable: false,
    },
    {
      field: 'couponCode',
      headerName: t('website_settings.coupon_code'),
      flex: 1,
      sortable: false,
      filterable: false,
      editable: false,
    },
    {
      field: 'amount',
      headerName: t('website_settings.amount_percent'),
      flex: 1,
      sortable: false,
      editable: false,
      filterable: false,
      renderCell: ({ row, value }) => {
        return row.percent ? (
          <Typography sx={{ textAlign: 'right', width: '100%' }}>{`${row.percent}%`}</Typography>
        ) : (
          <Typography sx={{ textAlign: 'right', width: '100%' }}>{`$${row.amount}`}</Typography>
        );
      },
    },
    {
      field: 'startDate',
      headerName: t('website_settings.start_date_'),
      editable: false,
      flex: 1,
      sortable: false,
      filterable: false,
      renderCell: ({ value }) => {
        return moment(value).format('MM/DD/YYYY');
      },
    },
    {
      field: 'endDate',
      headerName: t('website_settings.end_date_'),
      editable: false,
      flex: 1,
      sortable: false,
      filterable: false,
      renderCell: ({ value }) => {
        return moment(value).format('MM/DD/YYYY');
      },
    },
    {
      field: 'useForMarketing',
      headerName: t('menu.available_marketing'),
      editable: false,
      flex: 1,
      sortable: false,
      filterable: false,
      renderCell: ({ row }) => {
        if (row?.type && row?.type === MarketingTypes.SYSTEM) {
          return <img src={CheckInactive} alt="ONLINE_ICON" />;
        } else if (row?.type && row?.type === MarketingTypes.MARKETING) {
          return <img src={CheckActive} alt="ONLINE_ICON" />;
        }
      },
    },
    {
      field: 'shortLink',
      headerName: t('Website link'),
      editable: false,
      flex: 1,
      minWidth: 150,
      sortable: false,
      filterable: false,
      renderCell: ({ row }) => {
        const baseUrl = currentBusiness?.settings?.baseUrl || '';
        const alisa = currentBusiness?.settings?.url || '';
        const currentUrl = convertShortLInk(baseUrl, alisa, row?.couponCode);
        return (
          <Box sx={{ display: 'flex', gap: '2px', width: '100%', position: 'relative' }}>
            <Tooltip title={currentUrl} placement={'top'}>
              <StyledTitleShortLink>{currentUrl || ''}</StyledTitleShortLink>
            </Tooltip>
            <StyledButton
              sx={{ width: '20px' }}
              loading={false}
              variant="text"
              startIcon={
                <ContentCopyOutlinedIcon
                  style={{
                    color: '#929398',
                    width: '24px',
                    height: '24px'
                  }}
                />
              }
              onClick={() => handleCopy(currentUrl, row?.id)}
            >
            </StyledButton>
            {(showCopyMessage && row?.id === copiedRowId) && (
              <CopiedMessageShortLink className={`message-container ${showCopyMessage ? 'show fade-in' : ''}`}>
                <Typography color="white" variant="body2" className="message-text">
                  {t('Copied')}
                </Typography>
              </CopiedMessageShortLink>
            )}
          </Box>
        );
      },
    },
    {
      field: 'freeDelivery',
      headerName: t('website_settings.free_delivery'),
      editable: false,
      flex: 1,
      sortable: false,
      filterable: false,
      renderCell: ({ row }) => {
        return <img src={row.freeDelivery ? CheckActive : CheckInactive} alt="ONLINE_ICON" />;
      },
    },
  ];
  const expiredCoupons = useMemo(
    () => [
      ...genericColumns,
      {
        field: 'Tools',
        headerName: t('settings.tools'),
        type: 'actions',
        flex: 1,
        renderCell: (params) => {
          const isMultipleIds = params?.row?.businessIds?.length > 1 || params?.row?.businessUuids?.length > 1;
          return (
            <Box sx={{ display: 'flex' }}>
              <IconButton
                aria-label="delete"
                disabled={isMultipleIds}
                onClick={() => handleEdit(params?.row)}
              >
                <EditIcon />
              </IconButton>
              <IconButton
                disabled={isMultipleIds}
                aria-label="delete"
                onClick={() => handleDelete(params?.row)}
              >
                <img
                  style={{ opacity: isMultipleIds ? .5 : 1  }}
                  src={DeleteIcon} alt="delete icon"
                />
              </IconButton>
            </Box>
          );
        },
      },
    ],
    [i18n.language, showCopyMessage, copiedRowId]
  );

  return expiredCoupons;
};
