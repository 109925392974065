import * as yup from 'yup';

export const minOrderSchema = yup.object().shape({
  delivery: yup.object().shape({
    amount: yup
      .number()
      .min(0, 'min_order_or_equal_to_0')
      .nullable(true)
      .transform((value) => (isNaN(value) ? null : value)),
    enabled: yup.boolean(),
  }),
  pickup: yup.object().shape({
    amount: yup
      .number()
      .min(0, 'min_order_or_equal_to_0')
      .nullable(true)
      .transform((value) => (isNaN(value) ? null : value)),
    enabled: yup.boolean(),
  }),
});

export const deliveryLimitsSchema = yup.object().shape({
  maxDeliveryFee: yup
    .number()
    .min(0, 'min_order_or_equal_to_0')
    .nullable(true)
    .transform((value) => (isNaN(value) ? null : value)),
  maxDeliveryRadius: yup
    .number()
    .min(0, 'min_order_or_equal_to_0')
    .nullable(true)
    .transform((value) => (isNaN(value) ? null : value)),
})

const dropoffVerificationSchema = yup.object().shape({
  leaveAtDoor: yup.object().shape({
    enabled: yup.boolean(),
    picture: yup.boolean()
  }),
  meetAtDoor: yup.object().shape({
    enabled: yup.boolean(),
    signature: yup.boolean(),
    pinCode: yup.boolean(),
    barCode: yup.boolean(),
  }),
});

export const websiteInfo = yup.object().shape({
  minOrderObj: minOrderSchema,
  deliveryLimits: deliveryLimitsSchema,
  minOrder: yup
    .number()
    .min(0, 'min_order_or_equal_to_0')
    .nullable(true)
    .transform((value) => (isNaN(value) ? null : value)),
  deliveryBy: yup.array().of(
    yup.object().shape({
      maxDeliveryFee: yup
        .number()
        .min(0, 'max_delivery_equal_to_0')
        .transform((value) => (isNaN(value) ? null : value))
        .nullable(),
      dropoffVerification: dropoffVerificationSchema,
    }),
  ),
  deliveryFee: yup
    .number()
    .min(0, 'delivery_fee_equal_to_0')
    .transform((value) => (isNaN(value) ? -1 : value))
    .required('field_is_required_'),
  radius: yup
    .number()
    .min(1, 'radius_must_equal_to_1')
    .transform((value) => (isNaN(value) ? 0 : value))
    .required('field_is_required_'),
  url: yup.string().required('field_is_required_'),
  pickup: yup.boolean(),
  curbsidePickup: yup.boolean(),
  delivery: yup.boolean(),
  autoConfirm: yup.number(),
  deliveryFlatFeeSettings: yup.object().shape({
    flatFeeEnabled: yup.boolean(),
    deliveryRateSettings: yup.array().of(
      yup.object().shape({
        deliveryRadiusFrom: yup.number().required('field_is_required_'),
        deliveryRadiusTo: yup
          .number()
          .min(1, 'radius_must_equal_to_1')
          .transform((value) => (isNaN(value) ? 0 : value))
          .required('field_is_required_'),
        deliveryPrice: yup.number(),
        providerLimitFeeEnabled: false,
        providerLimitedFee: 0,
      })
    ),
  }),
  strategyId: yup.string(),
});
export const websiteInfoWithoutRadius = yup.object().shape({
  minOrderObj: minOrderSchema,
  deliveryLimits: deliveryLimitsSchema,
  minOrder: yup
    .number()
    .min(0, 'min_order_or_equal_to_0')
    .nullable(true)
    .transform((value) => (isNaN(value) ? null : value)),
  deliveryBy: yup.array().of(
    yup.object().shape({
      maxDeliveryFee: yup
        .number()
        .min(0, 'max_delivery_equal_to_0')
        .transform((value) => (isNaN(value) ? null : value))
        .nullable(),
      dropoffVerification: dropoffVerificationSchema,
    })
  ),
  deliveryFee: yup
    .number()
    .min(0, 'delivery_fee_equal_to_0')
    .transform((value) => (isNaN(value) ? -1 : value))
    .required('field_is_required_'),
  url: yup.string().required('field_is_required_'),
  radius: yup
    .number()
    .min(1, 'radius_must_equal_to_1')
    .transform((value) => (isNaN(value) ? 0 : value))
    .required('field_is_required_'),
  pickup: yup.boolean(),
  curbsidePickup: yup.boolean(),
  delivery: yup.boolean(),
  autoConfirm: yup.number(),
  deliveryFlatFeeSettings: yup.object().shape({
    flatFeeEnabled: yup.boolean(),
    deliveryRateSettings: yup.array().of(
      yup.object().shape({
        deliveryRadiusFrom: yup.number().required('field_is_required_'),
        deliveryRadiusTo: yup
          .number()
          .min(1, 'radius_must_equal_to_1')
          .transform((value) => (isNaN(value) ? 0 : value))
          .required('field_is_required_'),
        deliveryPrice: yup.number(),
      })
    ),
  }),
});

export const notDeliveryWebsiteInfo = yup.object().shape({
  minOrder: yup
    .number()
    .min(0, 'min_order_or_equal_to_0')
    .transform((value) => (isNaN(value) ? null : value))
    .nullable(),
  deliveryBy: yup.array().of(
    yup.object().shape({
      maxDeliveryFee: yup
        .number()
        .min(0, 'max_delivery_equal_to_0')
        .transform((value) => (isNaN(value) ? null : value))
        .nullable(),
      dropoffVerification: dropoffVerificationSchema,
    })
  ),
  deliveryFee: yup.number(),
  radius: yup.number(),
  url: yup.string().required('field_is_required_'),
  pickup: yup.boolean(),
  curbsidePickup: yup.boolean(),
  delivery: yup.boolean(),
  autoConfirm: yup.number(),
});
