import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useForm, FormProvider } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { getAvailabilityCustom } from 'redux/actions/availability';
import * as yup from 'yup';
import Box from '@mui/material/Box';
import useQuery from 'hooks/useQuery';
import CategoryForm from 'components/MenuNew/FormView/CategoryForm';
import Preloader from 'components/Preloader';
import FormHeader from './FormHeader';
import FormFooter from './FormFooter';
import { confirm } from 'components/MenuNew/ConfirmModal';
import {
  addMenuCategory,
  deleteMenuCategory,
  setCategoryFromSocket,
  updateMenuCategory,
} from 'redux/actions/Menu/categories';
import UpdateAvailability from '../ProductForm/UpdateAvailability/UpdateAvailability';

import Private from 'components/Private';
import { SOLD_OUT_STATUS } from 'constants/enumSoldOut';
import { getMenusV2 } from 'redux/actions/Menu/menuV2';
import { getCategoryProductsV2 } from 'redux/actions/Menu/products';
import { SET_LOADING } from 'redux/actions/types';
import ConfirmPromptModal from 'components/ConfirmPromptModal/ConfirmPromptModal';
import { useTranslation } from 'react-i18next';
import { getPrinterTags } from 'redux/actions/printers';

const initialData = {
  name: '',
  sort: 0,
  description: '',
  availability: [],
  isActive: true,
  soldOut: {
    status: SOLD_OUT_STATUS.AVAILABILITY,
    soldOutDate: '',
  },
  printerTags: [],
};

const FormData = () => {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const history = useHistory();


  const [availability, handleAvailability] = useState([]);
  const { menu } = useSelector(({ menu }) => menu);
  const categoryFormInfo = yup.object().shape({
    name: yup.string().trim().required('field_is_required_').max(60, 'batch_report.category_max_message'),
    printerTags: yup.array(),
  });
  const [isOpen, setIsOpen] = useState(false);
  const { currentBusiness } = useSelector(({ business }) => business);

  const categoryFormMethods = useForm({
    defaultValues: initialData,
    mode: 'all',
    resolver: yupResolver(categoryFormInfo),
  });

  const {
    reset,
    getValues,
    watch,
    formState,
    register,
  } = categoryFormMethods;
  const [title, setTitle] = useState(getValues().name);
  const [loading, setLoading] = useState(false);
  const [submitted, setIsSubmitted] = useState(false);
  const query = useQuery();
  const mode = query.get('mode');
  const id = query.get('cid');
  const isInOverview = history.location.pathname.includes('/overview');
  const isEdit = mode === 'edit';

  useEffect(() => {
    setIsSubmitted(false);
    dispatch(getAvailabilityCustom()).then((data) => handleAvailability(data));
    dispatch(getPrinterTags());
  }, []);

  useEffect(() => {
    if (menu.categories) {
      const categoryData = menu?.categories?.find((item) => item._id === id);
      if (isEdit) {
        if (categoryData) {
          setLoading(false);
          setIsSubmitted(false);
          reset(categoryData, { keepDirty: false });
        }
      } else {
        reset(initialData);
        setIsSubmitted(false);
        setLoading(false);
      }
    }
  }, [id, mode, menu]);

  const handleValidSubmit = (values) => {
    setLoading(true);
    setIsSubmitted(true);
    if (isEdit) {
      return dispatch(updateMenuCategory(id, values)).finally(() => {
        reset(values, { keepDirty: false });
        setLoading(false);
      });
    }
    return dispatch(addMenuCategory(values))
      .then((res) => {
        history.push(`${history.location.pathname}?entityType=category&cid=${res._id}&mode=edit`);
        reset(res, { keepDirty: false });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleClickOpen = () => {
    setIsOpen(!isOpen);
  };

  const handleDelete = async () => {
    const confirmed = await confirm({
      title: 'confirm_modal.delete_category',
      message: 'menu.are_sure_you_want_to_deleted',
      cancelText: t('orders.cancel'),
      confirmText: t('table_ordering.yes_delete'),
      confirmColor: 'primary',
    });
    if (confirmed) {
      setLoading(true);
      dispatch(deleteMenuCategory(id)).finally(() => {
        history.push(history.location.pathname);
        dispatch(getMenusV2()).then((res) => {
          res.categories.forEach((item) => {
            dispatch(getCategoryProductsV2(item._id)).finally(() => {
              dispatch({ type: SET_LOADING, payload: false });
            });
          });
        });
        reset(initialData);
        dispatch(setCategoryFromSocket());
        setLoading(false);
      });
    }
  };

  useEffect(() => {
    setTitle(getValues().name);
  }, [formState]);

  watch((data, { name }) => {
    if (name === 'name') {
      setTitle(getValues().name);
    }
  });

  return (
    <FormProvider {...categoryFormMethods}>
      {isEdit && (
        <UpdateAvailability
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          availability={getValues()}
          currentBusiness={currentBusiness}
          dispatch={dispatch}
          register={register}
          title="category"
        />
      )}
      <form
        style={{ height: '100%', position: 'relative' }}
        onSubmit={categoryFormMethods.handleSubmit(handleValidSubmit)}
      >
        {categoryFormMethods.formState.isDirty &&
        Object.keys(categoryFormMethods.formState.dirtyFields).filter(
          (key) => categoryFormMethods.formState.dirtyFields[key]
        )?.length &&
        !submitted ? (
          <ConfirmPromptModal
            handleConfirm={categoryFormMethods.handleSubmit(handleValidSubmit)}
            submitData={categoryFormMethods.getValues()}
            hasUnsavedChanges={
              categoryFormMethods.formState.isDirty &&
              Object.keys(categoryFormMethods.formState.dirtyFields).filter(
                (key) => categoryFormMethods.formState.dirtyFields[key]
              )?.length &&
              !submitted
            }
            formState={categoryFormMethods}
          />
        ) : (
          <></>
        )}

        {loading && <Preloader overlay={true} />}
        <Box>
          <FormHeader
            mode={mode}
            menu={menu}
            id={id}
            connectedProviders={getValues().connectedProviders}
            title={title}
            handleDelete={handleDelete}
            targetData={getValues()}
            setLoading={setLoading}
          />
        </Box>
        <Box sx={{ height: 'calc(100% - 174.5px)', overflowY: 'auto', px: 4, py: 3 }}>
          <Private permission="MENU_EDIT" disabled={true}>
            <CategoryForm
              data={getValues()}
              handleClickOpen={handleClickOpen}
              availability={availability}
              isInOverview={isInOverview}
              mode={mode}
              categoryFormMethods={categoryFormMethods}
            />
          </Private>
        </Box>
        <Box>
          <FormFooter isDisable={formState.isDirty && Object.keys(formState.dirtyFields)?.length} mode={mode} />
        </Box>
      </form>
    </FormProvider>
  );
};

export default FormData;
