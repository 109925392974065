import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  selectUnSelect,
  selectSelectedLocations,
  selectLocationIdsForReport,
  selectAllLocationsDependsOnBusinessGroup
} from 'redux/selectors';
import { sortBySelectedValues } from 'utils/extractIds';


const useLocationMultiSelect = (type) => {
  const [options, setOptions] = useState([]);

  const unSelect = useSelector(selectUnSelect);
  const selectedLocations = useSelector(selectSelectedLocations);
  const locationIdsForReport = useSelector(selectLocationIdsForReport);
  const allLocationsDependsOnBusinessGroup = useSelector(selectAllLocationsDependsOnBusinessGroup);

  const sortOptions = () => {
    const sortedLocationOptions = sortBySelectedValues(selectedLocations, allLocationsDependsOnBusinessGroup)
    setOptions(sortedLocationOptions);
  };

  useEffect(() => {
    if (allLocationsDependsOnBusinessGroup?.length) {
      setOptions(allLocationsDependsOnBusinessGroup);
      if (type) {
        sortOptions();
      }
    }
  },[allLocationsDependsOnBusinessGroup?.length,type])

  return {
    options,
    unSelect,
    sortOptions,
    selectedLocations,
    locationIdsForReport,
  }
}

export default useLocationMultiSelect;