import { useEffect, useState } from 'react';
import 'date-fns';
import { getReportList, setExportCSV, getChargeBackReportList, setExportChargeBackCSV } from 'redux/actions/reports';
import { useSelector, useDispatch } from 'react-redux';
import Preloader from 'components/Preloader';
import FilterReport from './Filter';
import TableReport from './Table';
import { EMPTY_REPORT_LIST, SET_REPORT_LOADING } from 'redux/actions/types';
import ErrorBoundaryWrapper from 'components/ErrorBoundaryWrapper';
import { allDays, columnTitles } from './configTable';
import useProviderConfig from 'hooks/useProviderConfig';
import { Paper } from '@mui/material';
import { useFilteredProviders } from 'components/Order/hooks/useFilteredProviders';

const tableHeadersHandler = () => {
  return columnTitles;
};

const Reports = () => {
  const dispatch = useDispatch();
  const { groups } = useProviderConfig();
  const { items } = useSelector(({ providers }) => providers);
  const { filteredProviders } = useFilteredProviders();
  const { headerKeys } = useSelector(({ reports }) => reports);
  const CHARGEBACK_DISPUTE_REPORT = 'CHARGEBACK_DISPUTE_REPORT';
  const { brandIdsForReport, locationIdsForReport } = useSelector(({ business }) => business);
  localStorage.removeItem('redirectUrl');

  const filterInitialValues = {
    type: 'SUMMARY',
    start_date: new Date(),
    end_date: new Date(),
    days_of_week: allDays.map((item) => item.value),
    brandIds: brandIdsForReport || [],
    locationIds: locationIdsForReport || [],
    providers: Object.keys(filteredProviders),
  };
  const { reportList, loading, filterList } = useSelector(({ reports }) => reports);
  const [model, setModel] = useState({
    ...filterInitialValues,
  });

  const [currentFilter, setCurrentFilter] = useState(null);
  const [filterSubmitted, handleFilterSubmitted] = useState(false);
  const [lastReportFilter, handleLastReportFilter] = useState(filterInitialValues);
  const [tableTypeName, handleTableTypeName] = useState(filterInitialValues.type);
  const [saveTypeValue, handleSaveTypeValue] = useState(filterList.filter((item) => item.name));
  const [page, setPage] = useState(0);
  const [loadingExport, setLoading] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [errorText, setErrorText] = useState(false);
  const [currentReport, setCurrentReport] = useState(filterInitialValues);

  useEffect(() => {
    setModel(filterInitialValues);
  }, [items, groups]);

  useEffect(() => {
    // dispatch(getReportFilter());
    // return () => {
    dispatch({ type: EMPTY_REPORT_LIST, payload: [] });
    // };
  }, []);

  const handleFilterSubmit = (modelData) => {
    handleFilterSubmitted(true);
    setCurrentReport(modelData);
    handleLastReportFilter(modelData);
    setPage(0);
    dispatch({ type: SET_REPORT_LOADING, payload: true });
    if (modelData.type === CHARGEBACK_DISPUTE_REPORT) {
      dispatch(
        getChargeBackReportList({
          ...modelData,
          start_date: modelData.start_date,
          end_date: modelData.end_date,
          isReporting: true
        })
      );
    } else {
      dispatch(
        getReportList({
          ...modelData,
          start_date: modelData.start_date,
          end_date: modelData.end_date,
          isReporting: true
        })
      );
    }
  };

  // Pagination
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // handleExport
  const handleExport = () => {
    setLoading(true);
    if (currentReport.type === CHARGEBACK_DISPUTE_REPORT) {
      dispatch(setExportChargeBackCSV({ ...lastReportFilter, isReporting: true }))
        .then(() => {
          setTimeout(() => {
            setLoading(false);
          }, 2000);
        })
        .catch(() => setLoading(false));
    } else {
      dispatch(setExportCSV({ ...lastReportFilter, isReporting: true }))
        .then(() => {
          setTimeout(() => {
            setLoading(false);
          }, 2000);
        })
        .catch(() => setLoading(false));
    }
  };
  return (
    <Paper sx={{ p: 3, }}>
      <ErrorBoundaryWrapper>
        <FilterReport
          tableTypeName={tableTypeName}
          model={model}
          currentFilter={currentFilter}
          setCurrentFilter={setCurrentFilter}
          filterInitialValues={filterInitialValues}
          errorText={errorText}
          handleTableTypeName={handleTableTypeName}
          setModel={setModel}
          handleFilterSubmit={handleFilterSubmit}
          setErrorText={setErrorText}
          filteredList={filteredProviders}
        />
        <TableReport
          lastReportFilter={lastReportFilter}
          currentFilter={currentFilter}
          currentReport={currentReport}
          loadingExport={loadingExport}
          tableTypeName={tableTypeName}
          filterSubmitted={filterSubmitted}
          loading={loading}
          reportList={reportList}
          page={page}
          saveTypeValue={saveTypeValue}
          rowsPerPage={rowsPerPage}
          tableHeadersHandler={tableHeadersHandler}
          setPage={setPage}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          setRowsPerPage={setRowsPerPage}
          handleExport={handleExport}
          headerKeys={headerKeys}
        />
      </ErrorBoundaryWrapper>
      {loading && <Preloader overlay />}
    </Paper>
  );
};

export default Reports;
