import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { Typography, CircularProgress, Box } from '@mui/material';


const LocationProgress = ({ progress, name }) => {

  const { t } = useTranslation();

  return (
    <Box className={`preloader ${'overlay'} `} style={{ background: 'rgb(255 255 255 / 88%)' }}>
      <Box className="loader_block">
        <Box sx={{ width: '100%', mt: -6, display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
          <Box sx={{ position: 'relative', display: 'inline-flex' }}>
            <CircularProgress variant="determinate" value={progress} />
            <Box
              sx={{
                top: 0,
                left: 0,
                bottom: 0,
                right: 0,
                position: 'absolute',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Typography variant="caption" component="div" color="text.secondary">
                {`${Math.round(progress)}%`}
              </Typography>
            </Box>
          </Box>
          <Typography sx={{ mt: 2 }}>{`Getting ${name} stores list...`}</Typography>
          <Typography>{t('header.it_may_take_while_please_wait')}</Typography>
        </Box>

      </Box>
    </Box>
  )
}

export default memo(LocationProgress)