export function extractIds(brands) {
  const ids = [];
  brands.forEach((brand) => {
    brand?.locations.forEach((location) => {
      ids.push(location?._id);
    });
  });
  return ids;
}

export const sortBySelectedValues = (selectedValues, allValues) => {
  const selectedValuesSet = new Set(selectedValues.map((item) => item.value));
  return [...allValues].sort((a, b) => {
    const isASelected = selectedValuesSet.has(a.value);
    const isBSelected = selectedValuesSet.has(b.value);
    if (isASelected && !isBSelected) return -1;
    if (!isASelected && isBSelected) return 1;
    return 0;
  });
}