import { Box, Grid } from '@mui/material';
import { useSelector } from 'react-redux';
import MenuItem from '@mui/material/MenuItem';
import { useTranslation } from 'react-i18next';
import { PRINTER_TYPES } from 'constants/printers';
import { selectPrinterTagsList } from 'redux/selectors';
import CustomCheckbox from 'components/Forms/CustomCheckbox';
import CustomTextInput from 'components/Forms/CustomTextInput';
import LabelRequired from 'components/FormFields/LabelRequired';
import CustomPrinterTagsChip from 'components/Forms/CustomPrinterTagsChip';
import { PADDING_MODAL_ACTION_LEFT, PADDING_MODAL_ACTION_RIGHT } from 'pages/TableOrdering/config';


const CreateAndEditPrinter = ({ getValues }) => {

  const { t } = useTranslation();
  const printerTagsList = useSelector(selectPrinterTagsList);

  return (
    <>
      <Box sx={{ mt: 1 }}>
        <Grid container spacing={2}>
          <Grid item md={12} xs={12} lg={12} xl={12}>
            <Box sx={{
              ...PADDING_MODAL_ACTION_LEFT,
              ...PADDING_MODAL_ACTION_RIGHT
            }}>
              <CustomTextInput
                sx={{ width: '100%', height: '60px' }}
                name="type"
                select={true}
                label={<LabelRequired>{t('Type')}</LabelRequired>}
              >
                {Object.keys(PRINTER_TYPES).map((item, index) => {
                  return <MenuItem key={index} value={item.toLowerCase()}>{item}</MenuItem>;
                })}
              </CustomTextInput>
            </Box>
          </Grid>
          <Grid item md={6} xs={6} lg={6} xl={6}>
            <Box sx={PADDING_MODAL_ACTION_LEFT}>
              <CustomTextInput
                sx={{ width: '100%', height: '60px' }}
                name="name"
                label={<LabelRequired>{t('Name')}</LabelRequired>}
              />
            </Box>
          </Grid>
          <Grid item md={6} xs={6} lg={6} xl={6}>
            <Box sx={PADDING_MODAL_ACTION_RIGHT}>
              <CustomTextInput
                sx={{ width: '100%', height: '60px' }}
                name="ipAddress"
                disabled={getValues('type') === 'internal'}
                label={
                  getValues('type') === 'internal' || getValues('innerMacAddress') ? (
                    t('settings.ip_address')
                  ) : (
                    <LabelRequired>{t('settings.ip_address')}</LabelRequired>
                  )
                }
              />
            </Box>
          </Grid>
          <Grid item md={6} xs={6} lg={6} xl={6}>
            <Box sx={PADDING_MODAL_ACTION_LEFT}>
              <CustomTextInput
                sx={{ width: '100%', height: '60px' }}
                inputProps={{
                  type: !getValues('innerMacAddress') ? 'number' : 'string',
                  step: 'any',
                  min: '0',
                }}
                disabled={getValues('type') === 'internal'}
                name="port"
                label={
                  getValues('type') === 'internal' || getValues('innerMacAddress') ? (
                    t('settings.port')
                  ) : (
                    <LabelRequired>{t('settings.port')}</LabelRequired>
                  )
                }
              />
            </Box>
          </Grid>
          <Grid item md={6} xs={6} lg={6} xl={6}>
            <Box sx={PADDING_MODAL_ACTION_RIGHT}>
              <CustomTextInput
                sx={{ width: '100%', height: '60px' }}
                select={true}
                name="ticketStyle"
                label={t('settings.ticket_font_size')}
              >
                <MenuItem value="regular">{t('settings.regular')}</MenuItem>
                <MenuItem value="large">{t('settings.large')}</MenuItem>
              </CustomTextInput>
            </Box>
          </Grid>
          <Grid item md={12} xs={12} lg={12} xl={12}>
            <Box
              sx={{
                ...PADDING_MODAL_ACTION_LEFT,
                ...PADDING_MODAL_ACTION_RIGHT
              }}
            >
              <CustomPrinterTagsChip
                name="printerTags"
                label="Printer tags"
                options={printerTagsList}
              />
            </Box>
          </Grid>

          <Grid item md={12} xs={12} lg={12} xl={12}>
            <Box
              sx={{
                ...PADDING_MODAL_ACTION_LEFT,
                ...PADDING_MODAL_ACTION_RIGHT
              }}
            >
              <CustomCheckbox
                checked={getValues('default')}
                label={t('Make as default printer')}
                name="default"
              />
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  )
}

export default CreateAndEditPrinter;