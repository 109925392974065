import * as yup from 'yup';

export const brandSignUp = yup.object().shape({
  brandName: yup.string().required('field_is_required_').trim(),
  metadata: yup.object().shape({
    communicationEmail: yup.string().test('sendToEmail', 'field_is_required_', (val) => {
      const regexp = /^(([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5}){1,25})(;(([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5}){1,25}))*$/g;
      return regexp.test(val.replace(/\+/g, '').trim());
    }),
  }),
  cloneMenu: yup.boolean(),
  businessId: yup.string(),
});
