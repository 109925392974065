import { useContext, useEffect, useState } from 'react';

import AcceptRequirements from 'components/ApplyCoupon/AcceptRequirements';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Typography,
} from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import ApplyCoupon from 'components/ApplyCoupon/ApplyCoupon';
import LabelRequired from 'components/FormFields/LabelRequired';
import CustomSelectAddItem from 'components/Forms/CustomSelectAddItem';
import CustomTextInput from 'components/Forms/CustomTextInput';
import SubscriptionPlans from 'components/SubscriptionPlans';
import { PaymentPeriodicityContext } from 'context/paymentPeriodicityContext';
import { SubscriptionContext } from 'context/subscriptionPlanConfigContext';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { generateLink } from 'redux/actions/userAuthV2';
import instance from 'services/api';

import DialogAddSelectItem from '../AddLocation/DialogAddItem/DialogAddSelectItem';
import { modalBrandFormSchema, schemaLocation } from './schema';

const InvitationEmail = ({ data, setData }) => {
  const { t } = useTranslation();

  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [selectedBrandName, setSelectedBrandName] = useState('');
  const [selectedPlan, handleSelectedPlan] = useState(
    localStorage.getItem('currentPlan') || ''
  );
  const [selectedPlanPrice, getSelectedPlanPrice] = useState('');
  const [showSubscription, setShowSubscription] = useState(false);
  const { periodicity } = useContext(PaymentPeriodicityContext);
  const [selectedPlanData, setSelectedPlanData] = useState(null);
  const [couponValue, setCouponValue] = useState('');
  const [isApply, setIsApplay] = useState(false);
  const { setUpFeeEnabled } = useContext(SubscriptionContext);
  const [checkNewPlans, setCheckNewPlans] = useState(false);
  const [
    acceptAgreeToReceiveOrderCommunication,
    setAcceptAgreeToReceiveOrderCommunication,
  ] = useState(false);
  const [
    acceptAgreeToReceiveOrderCommunicationError,
    setAcceptAgreeToReceiveOrderCommunicationError,
  ] = useState(false);
  const [agreeToPayRecurringCharges, setAgreeToPayRecurringCharges] =
    useState(false);
  const [agreeToPayRecurringChargesError, setAgreeToPayRecurringChargesError] =
    useState(false);
  const [agreeToPaySetupFee, setAgreeToPaySetupFee] = useState(false);
  const [agreeToPaySetupFeeError, setAgreeToPaySetupFeeError] = useState(false);
  const brandFormMethods = useForm({
    defaultValues: {
      email: '',
      brandName: '',
      firstName: '',
      lastName: '',
      // metadata: { communicationEmail: '' },
      paymentAvailable: true,
    },
    mode: 'all',
    resolver: yupResolver(schemaLocation),
  });
  const {
    formState: { isSubmitted: isSubscriptionSubmitted },
  } = brandFormMethods;

  const modalBrandFormMethods = useForm({
    defaultValues: { brandPhone: '', customBrandName: '' },
    mode: 'all',
    resolver: yupResolver(modalBrandFormSchema),
  });

  const {
    formState: { isSubmitted, errors },
    control,
  } = modalBrandFormMethods;

  const handleCheck = (event) => {
    handleSelectedPlan('');
    // handleAcceptCheckbox(false);
    setAcceptAgreeToReceiveOrderCommunicationError(false);
    setAcceptAgreeToReceiveOrderCommunication(false);
    setAgreeToPayRecurringChargesError(false);
    setAgreeToPayRecurringCharges(false);
    setAgreeToPaySetupFee(false);
    setAgreeToPaySetupFeeError(false);
    setShowSubscription(event.target.checked);
    if (!event.target.checked) {
      handleSelectedPlan('');
      setCouponValue('');
      setIsApplay(false);
    }
  };
  const brandName = modalBrandFormMethods.getValues().customBrandName;
  const handleFormSubmit = (values) => {
    setLoading(true);
    const currentBrand = JSON.parse(localStorage.getItem('currentBrand'));
    values['oneTimeSetupFeeCouponCode'] = isApply ? couponValue : '';
    const data = {
      email: values.email,
      brandName: currentBrand?.brandName || brandName,
      firstName: values.firstName,
      lastName: values.lastName,
      brandId: currentBrand?._id,
      planId: selectedPlan,
      paymentTerm: periodicity,
      oneTimeSetupFeeCouponCode: showSubscription
        ? values.oneTimeSetupFeeCouponCode
        : '',
      employeeNumber: showSubscription
        ? values.oneTimeSetupFeeCouponCode || ''
        : '',
      agreeToPaySetupFee:
        showSubscription && Number(setUpFeeEnabled) === 1
          ? agreeToPaySetupFee
          : false,
      agreeToPayRecurringCharges: showSubscription
        ? agreeToPayRecurringCharges
        : false,
      agreeToReceiveOrderCommunication: showSubscription
        ? acceptAgreeToReceiveOrderCommunication
        : false,
    };
    return generateLink(data)
      .then(() => {
        localStorage.removeItem('currentPlan');
        localStorage.removeItem('currentBrand');
        localStorage.removeItem('currentSetupFee');
        setCouponValue('');
        setIsApplay(false);
        history.push('settings/brands');
      })
      .finally(() => setLoading(false));
  };

  const handleClose = () => {
    setOpen(false);
    modalBrandFormMethods.reset();
  };

  const handleAddItem = (value) => {
    const selectedBrand = brandFormMethods.getValues().customBrandName;
    setSelectedBrandName(brandName);
    modalBrandFormMethods.setValue(
      'customBrandName',
      value ?? selectedBrand ?? ''
    );
    setOpen(true);
  };
  const handleClickSave = (value) => {
    const body = {
      brandName: value.customBrandName,
      brandPhone: value.brandPhone,
    };
    setSelectedBrandName(brandName);
    brandFormMethods.setValue('brandName', value.customBrandName);
    if (value.customBrandName) {
      brandFormMethods.clearErrors('brandName');
    }
    if (value.customBrandName) {
      instance.post(`/brand`, body).then((res) => {
        localStorage.setItem('currentBrand', JSON.stringify(res?.data?.data));
        setData([...data, res.data.data]);
      });
      handleClose();
    }
  };
  // const handleAcceptChange = ({ target }) => {
  //   handleAcceptCheckbox(target.checked);
  //   !target.checked && localStorage.removeItem('currentPlan');
  //   !target.checked ? handleErrorAccept(true) : handleErrorAccept(false);
  // };
 
  useEffect(() => {
    return () => {
      localStorage.removeItem('currentPlan');
      setAcceptAgreeToReceiveOrderCommunicationError(false);
      setAcceptAgreeToReceiveOrderCommunication(false);
      setAgreeToPayRecurringChargesError(false);
      setAgreeToPayRecurringCharges(false);
      setAgreeToPaySetupFee(false);
      setAgreeToPaySetupFeeError(false);
      // handleAcceptCheckbox(false);
      handleSelectedPlan('');
    };
  }, []);

  // newFunctions For accepting
  const handleAcceptAgreeToReceiveOrderCommunication = ({ target }) => {
    setAcceptAgreeToReceiveOrderCommunication(target.checked);
    !target.checked
      ? setAcceptAgreeToReceiveOrderCommunicationError(true)
      : setAcceptAgreeToReceiveOrderCommunicationError(false);
  };

  const handleAcceptAgreeToPayRecurringCharges = ({ target }) => {
    setAgreeToPayRecurringCharges(target.checked);
    !target.checked
      ? setAgreeToPayRecurringChargesError(true)
      : setAgreeToPayRecurringChargesError(false);
  };

  const handleAcceptSetupFree = ({ target }) => {
    setAgreeToPaySetupFee(target.checked);
    !target.checked
      ? setAgreeToPaySetupFeeError(true)
      : setAgreeToPaySetupFeeError(false);
  };

  return (
    <Box>
      <DialogAddSelectItem
        open={open}
        modalBrandFormMethods={modalBrandFormMethods}
        handleClickSave={handleClickSave}
        handleClose={handleClose}
      />
      <FormProvider {...brandFormMethods}>
        <form onSubmit={brandFormMethods.handleSubmit(handleFormSubmit)}>
          <Grid container spacing={2}>
            <Grid item xs={12} sx={{ height: '72px' }}>
              <CustomSelectAddItem
                name="brandName"
                label={
                  <LabelRequired>{t('sign_up.brands_list')}</LabelRequired>
                }
                control={control}
                errors={isSubmitted ? errors : null}
                handleAddItem={handleAddItem}
                options={data}
                setValue={brandFormMethods.setValue}
                setSelectedBrandName={setSelectedBrandName}
                selectedBrandName={selectedBrandName}
                sx={{ width: '100%' }}
              />
            </Grid>
            <Grid item xs={6} sx={{ height: '72px' }}>
              <CustomTextInput
                sx={{ width: '100%', mb: 1 }}
                inputProps={{ type: 'string' }}
                name="firstName"
                label={
                  <LabelRequired>
                    {t('account_information.first_name')}
                  </LabelRequired>
                }
              />
            </Grid>
            <Grid item xs={6} sx={{ height: '72px' }}>
              <CustomTextInput
                sx={{ width: '100%', mb: 1 }}
                inputProps={{ type: 'string' }}
                name="lastName"
                label={
                  <LabelRequired>
                    {t('account_information.last_name')}
                  </LabelRequired>
                }
              />
            </Grid>
            <Grid item xs={12} sx={{ height: '72px' }}>
              <CustomTextInput
                sx={{ width: '100%', mb: 1 }}
                inputProps={{ type: 'string' }}
                name="email"
                label={
                  <LabelRequired>{t('sign_in.email_address')}</LabelRequired>
                }
              />
            </Grid>
            {/* <Grid item md={12} xs={6}>
              <Typography sx={{ fontWeight: 700, mt: 2 }}>{t('settings.communication_information_reports')}</Typography>
            </Grid>
            <Grid item md={6} xs={6}>
              <CustomTextInput
                // defaultValue=""
                // disabled={true}
                sx={{ width: '100%' }}
                name="metadata.communicationEmail"
                label={t('sign_in.communication_email')}
              />
            </Grid> */}
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Checkbox onChange={handleCheck} check={showSubscription} />
                }
                label={t('sign_up.use_available_payment')}
              />
            </Grid>
            <Grid item xs={12}>
              {showSubscription ? (
                <>
                  <Typography sx={{ mb: 1, fontSize: 20, fontWeight: 'bold' }}>
                    {t('sign_up.choose_subscription_plan')}
                  </Typography>
                  <Box sx={{ minHeight: 400 }}>
                    <SubscriptionPlans
                      required={true}
                      isSubmitted={isSubscriptionSubmitted}
                      getSelectedPlanPrice={getSelectedPlanPrice}
                      handleSelectedPlan={handleSelectedPlan}
                      selectedPlanPrice={selectedPlanPrice}
                      selectedPlan={selectedPlan}
                      setSelectedPlanData={setSelectedPlanData}
                      target="signup"
                      setCheckNewPlans={setCheckNewPlans}
                    />
                  </Box>
                </>
              ) : (
                ''
              )}
            </Grid>
            {showSubscription &&
            selectedPlan &&
            selectedPlan !== 'null' &&
            checkNewPlans ? (
              <Grid item xs={12}>
                <ApplyCoupon
                  couponValue={couponValue}
                  setCouponValue={setCouponValue}
                  setIsApplay={setIsApplay}
                  handleAcceptSetupFree={handleAcceptSetupFree}
                  acceptAgreeToPaySetupFeeCharges={agreeToPaySetupFee}
                  acceptAgreeToPaySetupFee={agreeToPaySetupFeeError}
                  showSubscription={showSubscription}
                  checkNewPlans={checkNewPlans}
                />
              </Grid>
            ) : (
              <></>
            )}

            {showSubscription && (
              <Grid item xs={12}>
                <AcceptRequirements
                  handleAcceptAgreeToReceiveOrderCommunication={
                    handleAcceptAgreeToReceiveOrderCommunication
                  }
                  acceptAgreeToReceiveOrderCommunication={
                    acceptAgreeToReceiveOrderCommunication
                  }
                  acceptAgreeToReceiveOrderCommunicationError={
                    acceptAgreeToReceiveOrderCommunicationError
                  }
                  selectedPlan={selectedPlan}
                  handleAcceptAgreeToPayRecurringCharges={
                    handleAcceptAgreeToPayRecurringCharges
                  }
                  agreeToPayRecurringCharges={agreeToPayRecurringCharges}
                  agreeToPayRecurringChargesError={
                    agreeToPayRecurringChargesError
                  }
                  selectedPlanData={selectedPlanData}
                  selectedPlanPrice={selectedPlanPrice}
                  checkNewPlans={checkNewPlans}
                />
              </Grid>
            )}
            <Grid
              justifyContent="flex-end"
              display="flex"
              gap={'10px'}
              item
              xs={12}
            >
              <Button
                sx={{
                  background: '#E3E3E3',
                  '&:hover': { background: '#E3E3E3' },
                  color: '#000000',
                }}
                variant="contained"
                color="secondary"
                size="medium"
                onClick={() => history.push('settings/brands')}
              >
                {t('orders.cancel')}
              </Button>
              {!setUpFeeEnabled ? (
                <Button
                  disabled={
                    loading ||
                    !brandFormMethods.formState.isValid ||
                    (showSubscription &&
                      checkNewPlans &&
                      (!selectedPlan ||
                        !acceptAgreeToReceiveOrderCommunication ||
                        !agreeToPayRecurringCharges)) ||
                    !selectedBrandName ||
                    (showSubscription &&
                      !checkNewPlans &&
                      (!selectedPlan ||
                        !acceptAgreeToReceiveOrderCommunication))
                  }
                  variant="contained"
                  color="primary"
                  type="submit"
                >
                  {loading && (
                    <CircularProgress
                      style={{ width: '25px', height: '25px' }}
                    />
                  )}
                  {!loading && t('order_view.submit')}
                </Button>
              ) : (
                <Button
                  disabled={
                    loading ||
                    !brandFormMethods.formState.isValid ||
                    (showSubscription &&
                      checkNewPlans &&
                      (!selectedPlan ||
                        !acceptAgreeToReceiveOrderCommunication ||
                        !agreeToPayRecurringCharges ||
                        !agreeToPaySetupFee)) ||
                    !selectedBrandName ||
                    (showSubscription &&
                      !checkNewPlans &&
                      (!selectedPlan ||
                        !acceptAgreeToReceiveOrderCommunication))
                  }
                  variant="contained"
                  color="primary"
                  type="submit"
                >
                  {loading && (
                    <CircularProgress
                      style={{ width: '25px', height: '25px' }}
                    />
                  )}
                  {!loading && t('order_view.submit')}
                </Button>
              )}
            </Grid>
          </Grid>
        </form>
      </FormProvider>
    </Box>
  );
};
export default InvitationEmail;
