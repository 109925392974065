import { useEffect, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { Link } from 'react-router-dom';
import List from '@mui/material/List';
import { styled } from '@mui/material/styles';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import Checkbox from '@mui/material/Checkbox';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import TagretMultySelect from './TagretMultySelect';
import { useTranslation } from 'react-i18next';

import {
  SET_LINK_PROVIDER_VISIBILITY,
  // SET_LINK_PROVIDER,
  SET_CONNECTED_PROVIDERS,
  RESET_LINK_PROVIDER,
  SET_CHECKED_PROVIDER,
} from 'redux/actions/types';
import Preloader from 'components/Preloader';
import { getDoordashMenuTargetData, linkProviderTarget } from 'redux/actions/Menu/menuV2';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { Divider } from '@mui/material';
import { APP_BUNDLE, WEB_BUNDLE } from 'constants/permissions';
import useProviderConfig from 'hooks/useProviderConfig';
import DoordashManualUpdate from 'pages/MenuNew/MenuLinkDrawer/DoordashManualUpdate';

const StyledTooltip = styled(({ className, ...props }) => <Tooltip {...props} classes={{ popper: className }} />)(
  ({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: '#f5f5f9',
      color: 'rgba(0, 0, 0, 0.87)',
      p: 2,
      fontSize: '12px',
      border: '1px solid #dadde9',
    },
  })
);

const MenuLinkDrawer = () => {
  const { t } = useTranslation();
  const { providers } = useProviderConfig();
  const dispatch = useDispatch();
  const { linkProvider } = useSelector(({ menu }) => menu);
  const billingPlan = useSelector(({ business }) => business);
  const { currentBusiness } = useSelector(({ business }) => business);
  const currentPlan = billingPlan?.currentBusiness?.plan?.value;

  const showTheDisabledProv = currentPlan != WEB_BUNDLE;
  const [confirmedMode, handleConfirmedMode] = useState(false);
  const [isSelected, setIsSelected] = useState([]);
  const [sendDataProvider, setSendDataProvider] = useState([]);
  const [isDisable, setIsDisable] = useState(false);

  const {
    connectedProviders,
    providerLinkVisibility,
    loading,
    target,
    targetConnectedProviders,
    // categoryId,
    targetData,
    doordashTargetList,
  } = linkProvider;

  const isTargetMenu = target === 'menu';

  const anchor = 'right';

  const showTheWebsite = Number(currentPlan) !== Number(APP_BUNDLE);

  const showOnlyOneMapping = useMemo(() => {
    if (!targetData) {
      return true;
    }
    if (!targetData.modifiers?.length) {
      return true;
    }
    if (targetData.modifiers?.length) {
      return targetData?.modifiers?.every(mod => mod?.isOnePrice === true);
    }
  }, [targetData]);

  const toggleDrawer = (open) => () => {
    dispatch({ type: SET_LINK_PROVIDER_VISIBILITY, payload: open });
  };

  const handleChange = ({ target }, provider) => {
    const { checked } = target;
    let updatedProviders = [...isSelected];

    if (checked) {
      updatedProviders = [...isSelected, { [provider]: checked }];
    } else {
      updatedProviders = [...isSelected, { [provider]: checked }];
    }

    setIsSelected(updatedProviders);
    dispatch({
      type: SET_CONNECTED_PROVIDERS,
      payload: { [provider]: checked },
    });
  };

  const handleLinkProvider = () => {
    if (!confirmedMode) {
      const oldData = JSON.parse(localStorage.getItem(SET_CHECKED_PROVIDER)) || [];
      const sendData = [];
      oldData.map((item) => {
        Object.keys(connectedProviders).map((newItems) => {
          if (
            Object.keys(item)[0] === newItems &&
            item[Object.keys(item)[0]] !== connectedProviders[newItems] &&
            connectedProviders[newItems]
          ) {
            sendData.push({ provider: Object.keys(item)[0] });
          }
        });
      });
      setSendDataProvider(sendData);
      return handleConfirmedMode(true);
    } else {
      setIsDisable(true);
      dispatch(linkProviderTarget(sendDataProvider))
        .then(() => {
          setIsDisable(true);
          dispatch({ type: SET_LINK_PROVIDER_VISIBILITY, payload: false });
        })
        .catch(() => {
          setIsDisable(false);
        });
      setIsSelected([]);
    }
  };

  useEffect(() => {
    if (!isTargetMenu && target) {
      dispatch(getDoordashMenuTargetData());
    }
  }, [dispatch, isTargetMenu, target]);

  useEffect(() => {
    if (!providerLinkVisibility) {
      dispatch({ type: RESET_LINK_PROVIDER });
      handleConfirmedMode(false);
    }
    setIsDisable(false);
  }, [providerLinkVisibility]);

  useEffect(() => {
    return dispatch({ type: RESET_LINK_PROVIDER });
  }, []);

  const handleCancel = () => {
    if (confirmedMode) {
      handleConfirmedMode(false);
    } else {
      toggleDrawer(false)();
    }
  };

  const arrayConnectedProviders = Object.keys(providers).filter((item) => targetConnectedProviders?.[item]);
  // if (!arrayConnectedProviders.includes(providers?.website?.srv) && showTheWebsite) {
  //   arrayConnectedProviders.push(providers?.website?.srv);
  // }
  const arrayDisconnectedProviders = Object.keys(providers).filter(
    (item) => !targetConnectedProviders[item] && !providers[item]?.hidden
  );

  const checkCurrentBusiness = useMemo(() => {
    return currentBusiness?.roles?.name === 'super';
  }, [currentBusiness]);

  if (!providerLinkVisibility) {
    return <></>;
  }

  return (
    <Drawer anchor={anchor} open={providerLinkVisibility} onClose={toggleDrawer(false)}>
      <Box sx={{ p: 3, position: 'relative', height: '100%' }}>
        {loading && <Preloader overlay={true} />}
        <Box>
          <Box>
            {isTargetMenu ? (
              <>
                <Typography sx={{ fontWeight: 'bold', lineHeight: '31.92px' }} variant="h5">
                  {t('menu.select_providers_menu_items')}
                </Typography>
                <Typography
                  component={'span'}
                  style={{
                    fontSize: '12px',
                    padding: '8px',
                    background: '#E2E2E2',
                    borderRadius: '100px',
                    marginLeft: '8px',
                    textTransform: 'capitalize',
                  }}
                >
                  {target.charAt(0).toUpperCase() + target.slice(1)}
                </Typography>
              </>
            ) : (
              <Typography sx={{ fontWeight: 'bold', lineHeight: '31.92px' }} variant="h5">
                {`“${targetData?.name}” ${target} mapping`}
              </Typography>
            )}
          </Box>
          <Typography sx={{ fontWeight: '400', lineHeight: '18px', fontSize: '14px', color: '#8C8C8C', mt: 1.5 }}>
            {!confirmedMode ? t('menu.you_can_share_providers_only') : t('menu.you_requested_mentioned_below')}
          </Typography>
          <Divider sx={{ my: 2 }} />
          {confirmedMode && (
            <Typography sx={{ color: '#141414', fontSize: '12px', my: 2 }}>
              <ErrorOutlineIcon sx={{ mr: 2 }} />
              {t('menu.all_previous_overwritten')}
            </Typography>
          )}
          <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
            {arrayConnectedProviders.map((provider, index) => {
              if ((!showTheWebsite && provider === 'website') || provider === 'main' || provider === 'food2') {
                return '';
              }
              return (
                <ListItem
                  key={provider}
                  sx={{
                    pb: 2,
                    px: 0,
                    pt: index === 0 ? 0 : 2,
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'flex-start',
                    flexDirection:
                      targetData?.isOnePrice || !targetData?.hasOwnProperty('isOnePrice') || showOnlyOneMapping ? 'row' : 'column',
                  }}
                >
                  <Box sx={{ display: 'flex', mb: 3 }}>
                    <ListItemIcon>
                      <Box display="flex" alignItems="center">
                        {!confirmedMode ? (
                          <Checkbox
                            disabled={
                              (target === 'menu' && currentBusiness && currentBusiness?.roles?.name !== 'super') ||
                              (provider === providers?.doordash?.srv?.toLocaleLowerCase() &&
                                !connectedProviders[providers?.doordash?.srv?.toLocaleLowerCase()] &&
                                isTargetMenu) ||
                              (!targetConnectedProviders?.[provider] && (target === 'item' || target === 'category'))
                            }
                            onChange={(e) => handleChange(e, provider)}
                            defaultChecked={connectedProviders?.[provider]}
                            sx={{ p: 0 }}
                          />
                        ) : connectedProviders?.[provider] ? (
                          <CheckIcon />
                        ) : (
                          <CloseIcon />
                        )}
                        <Box sx={{ mx: 2 }}>
                          <img width="32px" alt={provider} src={providers?.[provider]?.logoUrl} />
                        </Box>
                      </Box>
                    </ListItemIcon>
                    <ListItemText primary={providers[provider].label} />
                  </Box>
                  {provider === providers.doordash.srv.toLowerCase() && target === 'menu' && checkCurrentBusiness ? (
                    <DoordashManualUpdate />
                  ) : <></>}
                  {provider === providers.doordash.srv.toLowerCase() && target !== 'menu' && checkCurrentBusiness && (
                    <TagretMultySelect showOnlyOneMapping={showOnlyOneMapping} target={target} targetData={targetData} data={doordashTargetList} />
                  )}
                </ListItem>
              );
            })}
          </List>
          {showTheDisabledProv && (
            <Box>
              <Typography
                sx={{
                  color: '#141414',
                  fontSize: '12px',
                  fontWeight: 500,
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                {t('menu.other_available_provider')}
                <StyledTooltip
                  title={
                    <Box sx={{ fontSize: '12px' }}>
                      <Typography sx={{ color: '#777777', mt: 1, fontSize: '12px' }}>
                        {t('menu.connect_more')} <Link to="/providers">{t('sidebar.providers')}</Link>
                        {t('menu.to_sync_your_menu')}
                      </Typography>
                    </Box>
                  }
                  // sx={{ ml: 2 }}
                >
                  <ErrorOutlineIcon sx={{ ml: 1, color: '#141414' }} />
                </StyledTooltip>
              </Typography>
              <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
                {arrayDisconnectedProviders.map((provider, index) => {
                  return (
                    <ListItem
                      key={provider}
                      sx={{
                        pb: 2,
                        pl: 0,
                        borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
                        pt: index === 0 ? 0 : 2,
                        opacity: '0.3',
                      }}
                    >
                      <ListItemIcon>
                        <Box display="flex" alignItems="center">
                          <Checkbox disabled={true} sx={{ p: 0 }} />
                          <Box sx={{ mx: 2 }}>
                            <img alt={provider} width="32px" src={providers[provider]?.logoUrl} />
                          </Box>
                        </Box>
                      </ListItemIcon>
                      <ListItemText primary={providers[provider].label} />
                    </ListItem>
                  );
                })}
              </List>
            </Box>
          )}
        </Box>
        <Box
          sx={{
            position: 'sticky',
            width: '100%',
            bottom: 0,
            px: 3,
            py: 2,
            left: 0,
            background: 'white',
            borderTop: (theme) => `1px solid ${theme.palette.divider}`,
          }}
        >
          {/* <Divider /> */}
          <Button
            id="menuLinkDrawerSave"
            disabled={
              isDisable || loading || (currentBusiness && currentBusiness?.roles?.name !== 'super' && target === 'menu')
            }
            onClick={handleLinkProvider}
            variant="contained"
            color="primary"
          >
            {!confirmedMode ? t('settings.save') : t('menu.proceed')}
          </Button>
          <Button id="menuLinkDrawerCancel" sx={{ ml: 2 }} onClick={handleCancel}>
            {t('order_view.cancel')}
          </Button>
        </Box>
      </Box>
    </Drawer>
  );
};

export default MenuLinkDrawer;
