import { useTranslation } from 'react-i18next';
import CustomTextInput from 'components/Forms/CustomTextInput';
import { TitleBufferTime } from 'redesign/BusinessTab/BusinessPrepTime/styles';
import {
  ORDER_BUFFER_TIME,
  pickUpBufferText,
} from 'redesign/BusinessTab/BusinessPrepTime/config';
import {
  FormControlLabel,
  FormGroup,
  Grid,
  MenuItem,
  Switch,
} from '@mui/material';

const PickUpBufferForProvider = ({
  prepTimeMethod,
  handlePickUpOrderBufferTime,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <Grid item xs={12} sx={{ mt: 2, pl: 2 }}>
        <FormGroup>
          <FormControlLabel
            sx={{ width: 'max-content' }}
            control={
              <Switch
                onChange={handlePickUpOrderBufferTime}
                checked={prepTimeMethod.getValues(
                  'orderBufferTime.pickUpOrderBufferTime.enabled'
                )}
              />
            }
            label={t('Enable Pick-Up Order Buffer Time')}
          />
        </FormGroup>
      </Grid>
      {prepTimeMethod.getValues(
        'orderBufferTime.pickUpOrderBufferTime.enabled'
      ) && (
        <Grid item xs={12} sx={{ pl: 2 }}>
          <CustomTextInput
            sx={{ width: '100%', maxWidth: '280px', mt: 2 }}
            select={true}
            name="orderBufferTime.pickUpOrderBufferTime.startTimeMin"
            label={t('First Order buffer time')}
          >
            {ORDER_BUFFER_TIME.map((option) => {
              return (
                <MenuItem key={option.key} value={option.value}>
                  {option.name}
                </MenuItem>
              );
            })}
          </CustomTextInput>
          <CustomTextInput
            sx={{ width: '100%', maxWidth: '280px', mt: 2, ml: 2 }}
            select={true}
            name="orderBufferTime.pickUpOrderBufferTime.endTimeMin"
            label={t('Last Order buffer time')}
          >
            {ORDER_BUFFER_TIME.map((option) => {
              return (
                <MenuItem key={option.key} value={option.value}>
                  {option.name}
                </MenuItem>
              );
            })}
          </CustomTextInput>
        </Grid>
      )}
      <Grid item xs={12} sx={{ mt: 2, pl: 2 }}>
        <TitleBufferTime>{pickUpBufferText}</TitleBufferTime>
      </Grid>
    </>
  );
};

export default PickUpBufferForProvider;
