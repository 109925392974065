export const AvailableButtons = {
  NEXT: 0,
  SUBMIT: 3,
};

export const SIGN_UP_FLOW = {
  SIGN_UP: 0,
  SIGN_UP_ACCOUNT_INFO: 1,
  SIGN_UP_LEGAL_INFO: 2,
  SIGN_UP_BILLING_INFO: 3,
  SIGN_UP_SUCCESS: 4,
};

export const SIGN_UP_FLOW_ADD_LOCATION = {
  ADD_LOCATION: 1,
  ADD_LOCATION_SUCCESS: 2,
};

export const SIGN_UP_FLOW_ADD_BRAND = {
  ADD_BRAND: 1,
  ADD_BRAND_SUCCESS: 2,
};

export const SIGN_UP_FLOW_AUTHORIZATION = {
  AUTHORIZATION_APPROVE: 1,
  AUTHORIZATION_ACCOUNT_INFO: 2,
  AUTHORIZATION_SERVICE_INFO: 3,
  AUTHORIZATION_BILLING_INFO: 4,
  AUTHORIZATION_SUCCESS: 5,
};

export const EOrderAvailableActions = {
  STATUS_CHANGE: 'statusChange',
};