import { useMemo, useEffect, useState } from 'react';
import * as yup from 'yup';
import { useForm, FormProvider, useWatch } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import Checkbox from '@mui/material/Checkbox';
import Table from '@mui/material/Table';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import MenuItem from '@mui/material/MenuItem';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import CustomTextInput from 'components/Forms/CustomTextInput';
import FormGroup from '@mui/material/FormGroup';
import { useTranslation } from 'react-i18next';
import ConfirmPromptModal from 'components/ConfirmPromptModal/ConfirmPromptModal';

const initialValues = {
  active: false,
  type: '',
  action: '',
  sendTo: '',
  copy: '',
};

const EventItem = ({
  printersList,
  handleMode,
  handleShowEvent,
  mode,
  handleSubmit,
  eventModel = initialValues,
  eventActions,
  eventDefault,
  handleEventModel,
  updateEventActions,
  setDirty = () => {},
  isDirtyEvent,
}) => {
  const { t } = useTranslation();
  const cells = [
    { name: t('settings.events_type'), flex: 3 },
    { name: t('Actions'), flex: 2 },
    { name: t('settings.send_to'), flex: 2 },
    { name: t('settings.copy'), flex: 1 },
    { name: t('header.active'), flex: 1 },
    { name: '', flex: 1 },
  ];
  const eventActionsEnums = {
    PrintTicket: t('settings.print_ticket'),
    PrintReceipt: t('settings.print_receipt'),
    SMS: t('settings.sms'),
    Fax: t('settings.fax'),
    Email: t('Email'),
    RunZReportAndEmail: t('settings.run_Z_report_and_email'),
    RunXReportAndEmail: t('settings.run_X_report_and_email'),
  };

  const schemaEventItem = yup.object().shape({
    type: yup.string().required('field_is_required_'),
    action: yup.string().required('field_is_required_'),
    sendTo: yup.string().required('field_is_required_'),
    copy: yup
      .number()
      .typeError('this_field_is_invalid_')
      .required('field_is_required_'),
    active: yup.boolean(),
  });
  const eventItemFormMethods = useForm({
    defaultValues: eventModel,
    mode: 'all',
    resolver: yupResolver(schemaEventItem),
  });
  const [error, setError] = useState('');
  const { control, getValues, register, resetField, setValue, formState } =
    eventItemFormMethods;
  const action = getValues('action') || eventModel.action;
  const sendTo = useWatch({ control, name: 'sendTo' });

  const actionPrintTicket = useMemo(() => {
    return eventActions.find(
      (item) => item.name.toLowerCase() === 'PrintTicket'.toLowerCase()
    );
  }, [eventActions]);

  const actionPrintReceipt = useMemo(() => {
    return eventActions.find(
      (item) => item.name.toLowerCase() === 'PrintReceipt'.toLowerCase()
    );
  }, [eventActions]);

  useEffect(() => {
    const currentEventActions = eventDefault.find(
      (item) => item._id === getValues().type
    )?.actions;
    if (currentEventActions) {
      updateEventActions(currentEventActions);
    }
  }, []);

  useEffect(() => {
    const values = getValues('action');
    const sendTo = getValues('sendTo');
    const currentEventActions = eventActions.find(
      (item) => item._id === values
    );
    if (currentEventActions && currentEventActions?.name === 'SMS') {
      const pattern = /^\d*$/;
      let isMatch = pattern.test(sendTo);
      !isMatch ? setError(t('phone_number_must')) : setError('');
    } else setError('');
  }, [sendTo, eventActions]);

  const handleChangeType = (e) => {
    const { value } = e.target;
    resetField('type', { defaultValue: value }, { keepDirty: true });
    const currentEventActions = eventDefault.find(
      (item) => item._id === value
    )?.actions;
    if (currentEventActions) {
      updateEventActions(currentEventActions);
      resetField(
        'action',
        { defaultValue: currentEventActions[0]._id },
        { keepDirty: true }
      );

      if (
        currentEventActions[0]._id !== actionPrintReceipt?._id &&
        currentEventActions[0]._id !== actionPrintTicket?._id
      ) {
        resetField('sendTo', { defaultValue: '' }, { keepDirty: true });
      }
    }
  };

  const handleChangeAction = (e) => {
    const { value, name } = e.target;
    if (value !== actionPrintReceipt?._id && value !== actionPrintTicket?._id) {
      resetField('sendTo', { defaultValue: '' }, { keepDirty: true });
    } else {
      const defaultVal =
        printersList?.filter((item) => item.default) || printersList[0];
      resetField(
        'sendTo',
        { defaultValue: defaultVal[0]?._id },
        { keepDirty: true }
      );
    }
    setValue(name, value, { shouldDirty: true });
  };

  const handleChangeSendTo = (e) => {
    resetField('sendTo');
    const { name, value } = e.target;
    setValue(name, value, { shouldDirty: true });
  };

  const cancelEditMode = () => {
    handleShowEvent(false);
    handleEventModel(initialValues);
    handleMode(null);
    eventItemFormMethods.reset(initialValues, { shouldDirty: false });
    setDirty(false);
  };

  const onSubmitForm = async (values) => {
    if (error) {
      return;
    }
    try {
      await handleSubmit({ ...values });
    } catch (err) {
      console.error('Form submission error:', err);
    }
  };

  const eventActionsList = useMemo(() => {
    if (!printersList.length) {
      const list = eventActions.filter(
        (item) => item.name !== 'PrintReceipt' && item.name !== 'PrintTicket'
      );
      return list;
    }
    return eventActions;
  }, [eventActions, printersList]);

  return (
    <Box>
      <Typography
        sx={{
          my: 2,
        }}
        variant="h5"
      >{`${mode} Event`}</Typography>

      <Paper
        sx={{
          position: 'relative',
          '& .MuiTableHead-root': {
            backgroundColor: (theme) => theme.palette.action.hover,
            '& .MuiTableCell-root': {
              alignItems: 'center',
              justifyContent: 'center',
            },
          },
        }}
      >
        <Box onClick={cancelEditMode}>
          <CloseOutlinedIcon
            sx={{
              position: 'absolute',
              top: 5,
              right: 5,
              fontSize: 25,
              cursor: 'pointer',
            }}
          />
        </Box>
        <FormProvider {...eventItemFormMethods}>
          <form onSubmit={eventItemFormMethods.handleSubmit(onSubmitForm)}>
            <ConfirmPromptModal
              handleConfirm={eventItemFormMethods.handleSubmit(onSubmitForm)}
              submitData={eventItemFormMethods.getValues()}
              hasUnsavedChanges={formState.isDirty || isDirtyEvent}
              formState={eventItemFormMethods}
              isFormError={error}
            />
            <TextField hidden name="id" value={eventModel._id} />
            <Table className="edit-table">
              <TableHead className="edit-thead">
                <TableRow
                  sx={{
                    display: 'flex',
                  }}
                >
                  {cells.map((item) => (
                    <TableCell
                      key={item.name}
                      sx={{ flex: item.flex, width: item.width }}
                    >
                      {t(item.name)}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow
                  sx={{
                    display: 'flex',
                    height: '84px',
                  }}
                >
                  <TableCell
                    sx={{
                      flex: 3,
                    }}
                  >
                    <CustomTextInput
                      sx={{ width: '100%' }}
                      select={true}
                      name="type"
                      label={t('Type')}
                      disabled={mode === 'Edit'}
                      onChange={handleChangeType}
                    >
                      {eventDefault.map((item) => (
                        <MenuItem key={item._id} value={item._id}>
                          {item.name}
                        </MenuItem>
                      ))}
                    </CustomTextInput>
                  </TableCell>
                  <TableCell
                    sx={{
                      flex: 2,
                    }}
                  >
                    <CustomTextInput
                      onChange={handleChangeAction}
                      sx={{ width: '100%' }}
                      select={true}
                      name="action"
                      label={t('Actions')}
                    >
                      {eventActionsList.map((item) => (
                        <MenuItem
                          data-id={item._id}
                          key={item._id}
                          value={item._id}
                        >
                          {eventActionsEnums[item.name]}
                        </MenuItem>
                      ))}
                    </CustomTextInput>
                  </TableCell>
                  <TableCell
                    sx={{
                      flex: 2,
                    }}
                  >
                    {action === actionPrintReceipt?._id ||
                    action === actionPrintTicket?._id ? (
                      <CustomTextInput
                        onChange={handleChangeSendTo}
                        sx={{ width: '100%' }}
                        select={true}
                        name="sendTo"
                        label={t('settings.send_to')}
                      >
                        {printersList.map((item) => (
                          <MenuItem key={item._id} value={item._id}>
                            {item.name}
                          </MenuItem>
                        ))}
                      </CustomTextInput>
                    ) : (
                      <>
                        <CustomTextInput
                          sx={{ width: '100%' }}
                          name="sendTo"
                          label={t('settings.send_to')}
                        />
                        {error && (
                          <Typography sx={{ color: 'red' }}>{error}</Typography>
                        )}
                      </>
                    )}
                  </TableCell>
                  <TableCell
                    sx={{
                      flex: 1,
                    }}
                  >
                    <CustomTextInput
                      sx={{ width: '100%' }}
                      disabled={true}
                      inputProps={{
                        type: 'number',
                      }}
                      name="copy"
                      label={t('settings.copy')}
                    />
                  </TableCell>
                  <TableCell
                    sx={{
                      flex: 1,
                      justifyContent: 'flex-start',
                    }}
                  >
                    <FormGroup
                      sx={{ display: 'flex', justifyContent: 'flex-start' }}
                      name="active"
                    >
                      <Checkbox
                        sx={{ width: 'min-content' }}
                        disableRipple={true}
                        {...register('active')}
                        defaultChecked={eventModel.active}
                      />
                    </FormGroup>
                  </TableCell>
                  <TableCell
                    sx={{
                      flex: 1,
                    }}
                  >
                    <div className="d-flex justify-content-end">
                      <Button
                        id="eventItemSave"
                        type="submit"
                        variant="contained"
                        color="primary"
                        className="mx-2 save-btn"
                        disabled={error}
                      >
                        {t('settings.save')}
                      </Button>
                    </div>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </form>
        </FormProvider>
      </Paper>
    </Box>
  );
};

export default EventItem;
