import { get } from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import {
  Box,
  Chip,
  FormHelperText,
  ListItemText,
  Typography,
  FormControl,
  OutlinedInput,
  Select,
  InputLabel,
  MenuItem,
} from '@mui/material';
import { useFormContext, Controller } from 'react-hook-form';

const CustomSelectChip = ({
  name,
  sx,
  label,
  options,
  multiple = true,
  MenuProps,
  formControlProps,
  maxShow = 3,
  ...params
}) => {
  const { control, formState } = useFormContext();
  const { errors } = formState;
  let parsedError = get(errors, name);

  return (
    <Controller
      {...params}
      name={name}
      control={control}
      render={({ field }) => {
        const { value, onChange } = field;

        return (
          <FormControl
            size="small"
            fullWidth
            error={!!parsedError}
            sx={sx}
            {...formControlProps}
          >
            <InputLabel
              id="demo-multiple-chip-label"
              sx={{ background: 'white' }}
            >
              {label}
            </InputLabel>

            <Select
              variant="outlined"
              labelId="demo-multiple-chip-label"
              id="demo-multiple-chip"
              multiple={multiple}
              value={value || []}
              onChange={onChange}
              label={label}
              input={
                <OutlinedInput
                  id={`${name}-select-outlined`}
                  label={label}
                />
              }
              MenuProps={MenuProps}
              sx={{
                '& .MuiSelect-select': {
                  padding: value ? '7px !important' : '8.5px 14px',
                },
              }}
              renderValue={(selected) => (
                <Box
                  sx={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    gap: 0.5,
                    padding: 0,
                  }}
                >
                  {selected.map((val, index) => {
                    const found = options.find((item) => item._id === val);
                    if (index < maxShow) {
                      return (
                        <Chip
                          key={val}
                          sx={{
                            height: '26px',
                            fontWeight: 500,
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            width: found?.label?.length > 10 ? '110px' : 'inherit',
                          }}
                          label={found?.label}
                        />
                      );
                    }
                    return null;
                  })}
                  {selected?.length > maxShow ? (
                    <Chip
                      key={`+${selected.length - maxShow}`}
                      sx={{ height: '26px', fontWeight: 500 }}
                      label={`+${selected.length - maxShow}`}
                    />
                  ) : null}
                </Box>
              )}
              InputLabelProps={{
                shrink: true,
              }}
            >
              {options.map((opt) => (
                <MenuItem key={opt?.id || uuidv4()} value={opt?._id}>
                  <ListItemText
                    disableTypography
                    primary={<Typography>{opt?.label}</Typography>}
                  />
                </MenuItem>
              ))}
            </Select>
            <FormHelperText>{parsedError?.message || ''}</FormHelperText>
          </FormControl>
        );
      }}
    />
  );
};

export default CustomSelectChip;
