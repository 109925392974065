import React, { useCallback } from 'react';
import { Button } from 'reactstrap';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Typography from '@mui/material/Typography';
import LocationProgress from 'pages/Providers/LocationProgress'
import { AvForm, AvField } from 'availity-reactstrap-validation';

const ConnectModal = ({ onSubmit, loading, progress, name, error }) => {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const handleSubmit = useCallback(
    (event, values) => {
      onSubmit(values);
      dispatch({ type: 'PROVIDER_CONNECT_CONVENTIONAL_ERROR', payload: '' });
    },
    [onSubmit]
  );
  return (
    <div className="provider-modal">
      <div className="provider-modal-content">
        <div className="provider-form">
          <AvForm onValidSubmit={handleSubmit}>
            <AvField
              name="username"
              label={t('providers_settings.username')}
              validate={{
                required: { value: true, errorMessage: t('field_is_required_') },
              }}
            />
            <AvField
              name="password"
              label={t('sign_in.password')}
              type="password"
              validate={{
                required: { value: true, errorMessage: t('field_is_required_') },
              }}
            />
            {error && <Typography sx={{ color: 'red', mt: 1, mb: 1 }}>{error}</Typography>}
            <Button id="connectModal" color="primary" type="submit">
              {t('website_settings.connect')}
            </Button>
          </AvForm>
        </div>
      </div>
      {!error && loading && progress !== 100 && (
        <LocationProgress progress={progress} name={name} />
      )}
    </div>
  );
};

export default React.memo(ConnectModal);
