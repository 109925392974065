import { useState } from 'react';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import DeleteIcon from 'assets/icons/TableDelete.png';
import { useDispatch, useSelector } from 'react-redux';
import { selectPrinterTagsList } from 'redux/selectors';
import { Box, IconButton, Tooltip } from '@mui/material';
import { confirm } from 'components/MenuNew/ConfirmModal';
import { DownloadCopyButtonTitle } from 'redesign/BusinessTab/styles';
import { MAX_ITEM_COUNT_IN_PRINTER, MODE } from 'pages/TableOrdering/config';
import {
  createPrinterTags,
  deletePrinterTags, getPrintersList,
  updatePrinterTags
} from 'redux/actions/printers';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';

const schemaTags = yup.object().shape({
  tagName: yup.string().required('field_is_required_').trim(),
});

const useTags = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const printerTagsList = useSelector(selectPrinterTagsList);

  const [mode, setMode] = useState(MODE.CREATE);
  const [loading, setLoading] = useState(false);
  const [openTagsModal, setOpenTagsModal] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(MAX_ITEM_COUNT_IN_PRINTER);

  const formMethods = useForm({
    defaultValues: { tagName:  '' },
    mode: 'all',
    resolver: yupResolver(schemaTags),
  });

  const handleCancel = () => {
    formMethods.reset({ tagName: '' });
    formMethods.clearErrors();
    setOpenTagsModal(false);
    setTimeout(() => {
      setMode(MODE.CREATE);
    }, 300);
  }

  const editTagItem = (row) => {
    const tag = {
      id: row?.id,
      tagName: row?.name
    };
    formMethods.reset(tag);
    setOpenTagsModal(true);
    setMode(MODE.EDIT);
  }

  const tagList = printerTagsList?.map((tag, index) => ({
    id: tag?._id || index,
    name: tag?.tagName || '',
  }));

  const handleDelete = async (e, id) => {
    e.stopPropagation();
    const option = {
      title: t('Confirm Tag Deletion'),
      message: t('Are you sure you want to delete the tag ?'),
      cancelText: t('orders.cancel'),
      confirmText: t('table_ordering.yes_delete'),
      confirmColor: 'primary',
      background: 'rgba(226, 21, 8, 1)',
    };
    const confirmed = await confirm(option);
    if (confirmed) {
      dispatch(deletePrinterTags(id));
      return true;
    }
  };

  const tagsColumns = [
    {
      field: 'tagName',
      headerName: t('Name'),
      flex: 1,
      sortable: false,
      filterable: false,
      editable: false,
      renderCell: ({ row }) => {
        if (row?.name) {
          return (
            <Tooltip title={row?.name} placement={'top'}>
              <DownloadCopyButtonTitle>
                {row?.name}
              </DownloadCopyButtonTitle>
            </Tooltip>
          )
        }
      }
    },
    {
      id: 'tools',
      field: 'Tools',
      type: 'actions',
      width: 100,
      renderCell: (params) => {
        return (
          <Box sx={{ display: 'flex' }}>
            <IconButton aria-label="delete" onClick={() => editTagItem(params?.row)}>
              <ModeEditOutlineOutlinedIcon />
            </IconButton>
            <IconButton
              aria-label="delete"
              disabled={true}
              sx={{ opacity: '0.5' }}
              onClick={(evenet) => handleDelete(evenet, params?.row?.id)}
            >
              <img src={DeleteIcon} alt="delete icon" />
            </IconButton>
          </Box>
        );
      },
    },
  ];

  const createTag = (values) => {
    setLoading(true);
    try {
      dispatch(createPrinterTags({ ...values })).then(() => {
        formMethods.clearErrors();
        formMethods.reset({ tagName: '' });
        setOpenTagsModal(false);
        setTimeout(() => {
          setMode(MODE.CREATE);
        }, 300);
      });
    }catch (e) {
      console.log(e, 'Error');
    }finally {
      setLoading(false);
    }
  }

  const editTag = (values) => {
    setLoading(true);
    try {
      dispatch(updatePrinterTags({ ...values })).then(() => {
        dispatch(getPrintersList());
        formMethods.clearErrors();
        formMethods.reset({ tagName: '' });
        setOpenTagsModal(false);
        setTimeout(() => {
          setMode(MODE.CREATE);
        }, 300);
      });
    }catch (e) {
      console.log(e, 'Error');
    }finally {
      setLoading(false);
    }
  }

  const handleSubmit = (values) => {
    if (mode === MODE.CREATE) {
      createTag(values)
    }else {
      editTag(values);
    }
  }

  return {
    formMethods,
    handleSubmit,
    mode,
    loading,
    openTagsModal,
    setOpenTagsModal,
    handleCancel,
    rowsPerPage,
    setRowsPerPage,
    tagList,
    tagsColumns,
  }
}

export default useTags;