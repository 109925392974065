import { memo } from 'react';
import { FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import LoadingButton from '@mui/lab/LoadingButton';
import CustomTextInput from 'components/Forms/CustomTextInput';
import LabelRequired from 'components/FormFields/LabelRequired';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import LocationProgress from 'pages/Providers/LocationProgress';
import { CustomDialogTitle } from 'pages/Auth/TwoFactor/TowFactorDialog';
import useEzCaterConnect from 'components/Dialog/EzCater/useEzCaterConnect';
import DraggableDialogWrapper from 'components/DraggableDialogWrapper/DraggableDialogWrapper';
import {
  Dialog,
  Box,
  DialogActions,
  Button, Grid,
  DialogContent,
  InputAdornment,
  IconButton,
  Typography
} from '@mui/material';


const EzCaterConnectDialog = ({
  open,
  handleClose,
  title,
  providerName,
  providerSRV,
  error,
  isLoading,
  progress,
  setSubmitted,
  locationsModalVisible
}) => {
  const { t } = useTranslation();
  const {
    handleSubmit,
    loading,
    formMethods,
    showPassword,
    visiblePassword
  } = useEzCaterConnect(open, handleClose, providerSRV, setSubmitted, locationsModalVisible);


  return (
    <Dialog
      open={open}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth={true}
      disableEscapeKeyDown={true}
      onClose={handleClose}
      PaperComponent={DraggableDialogWrapper}
      PaperProps={{
        sx: { width: '754px', maxWidth: '100%', minHeight: '580px', background: '#FAFAFA', cursor: 'grab' },
      }}
    >
      {!error && isLoading && progress !== 100 && <LocationProgress progress={progress} name={providerName} />}
      <FormProvider {...formMethods}>
        <form onSubmit={formMethods.handleSubmit(handleSubmit)}>
          <Box>
            <CustomDialogTitle id="customized-dialog-title" onClose={handleClose}>
              {`${t(title, { name: providerName })}`}
            </CustomDialogTitle>
            <DialogContent sx={{ width: '100%', cursor: 'initial' }} dividers>
              <Box sx={{ height: '415px', mt: 2 }}>
                <Grid spacing={2} container>
                  <Grid item md={12} sm={12} lg={6} sx={{ height: '72px' }}>
                    <CustomTextInput
                      disabled={false}
                      sx={{ width: '100%' }}
                      name="username"
                      label={<LabelRequired>{t('Username')}</LabelRequired>}
                    />
                  </Grid>
                  <Grid item md={12} sm={12} lg={6} sx={{ height: '72px' }}>
                    <CustomTextInput
                      disabled={false}
                      sx={{ width: '100%' }}
                      name="password"
                      label={<LabelRequired>{t('Password')}</LabelRequired>}
                      InputProps={{
                        type: showPassword ? 'text' : 'password',
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton aria-label="toggle password visibility" onClick={visiblePassword} edge="end">
                              {showPassword ? (
                                <VisibilityOff sx={{ fontSize: '1.2rem' }} />
                              ) : (
                                <Visibility sx={{ fontSize: '1.2rem' }} />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                  <Grid item md={12} sm={12} lg={12} sx={{ height: '72px' }}>
                    <CustomTextInput
                      disabled={false}
                      sx={{ width: '100%' }}
                      name="authKey"
                      label={<LabelRequired>{t('Auth key')}</LabelRequired>}
                    />
                  </Grid>
                  {error &&
                    <Grid item md={12} sm={12} lg={12} sx={{ height: '72px' }}>
                      <Typography sx={{ color: 'red', mt: 1, mb: 1 }}>{error}</Typography>
                    </Grid>
                  }
                </Grid>
              </Box>
            </DialogContent>
          </Box>
          <DialogActions>
            <Box sx={{ margin: '8px 16px' }}>
              <Button
                variant="outlined"
                onClick={handleClose}
                sx={{
                  mr: 1,
                  color: '#626A7A',
                  borderColor: '#626A7A',
                  '&:hover': { color: '#626A7A', borderColor: '#626A7A', background: 'white' },
                }}
              >
                {t('Cancel')}
              </Button>
              <LoadingButton
                loading={loading}
                variant="contained"
                type={'submit'}
                disabled={
                  !formMethods.formState.isDirty ||
                  !formMethods.formState.isValid || loading
                }
              >
                {t('Connect')}
              </LoadingButton>
            </Box>
          </DialogActions>
        </form>
      </FormProvider>
    </Dialog>
  )
}

export default memo(EzCaterConnectDialog)