export const MUI_TO_SX_STYLES = {
  mt: 2,
  '& .MuiDataGrid-columnHeader:focus': {
    outline: 'none',
  },
  '& .MuiDataGrid-columnHeader:focus-within': {
    outline: 'none',
  },
  '& .MuiDataGrid-columnHeader--selected': {
    outline: 'none',
  },
  '& .MuiDataGrid-row.Mui-selected': {
    backgroundColor: 'white',
  },
  '& .MuiDataGrid-row:hover.Mui-selected': {
    backgroundColor: 'white',
  },
  '& .MuiToolbar-root': {
    display: 'flex',
    alignItems: 'baseline',
    pt: 1,
  },
  '& .MuiDataGrid-footerContainer': {
    backgroundColor: 'white',
  },
  '& .MuiDataGrid-columnHeaders': {
    backgroundColor: '#E7E7E7',
    color: 'rgba(0, 0, 0, 0.87)',
  },
  '& >.MuiDataGrid-main': {
    '&>.MuiDataGrid-columnHeaders': {
      borderBottom: 'none',
    },

    '& div div div div >.MuiDataGrid-cell': {
      borderBottom: 'none',
    },
  },
  '& .MuiDataGrid-row	': {
    backgroundColor: 'white',
    borderBottom: '1px solid #E7E7E7',
  },
  '& .MuiDataGrid-sortIcon': {
    display: 'none',
  },
  '& .MuiDataGrid-filterIcon': {
    display: 'none',
  },
  '& .MuiDataGrid-columnSeparator': {
    display: 'none',
  },
  '& .MuiDataGrid-menuIcon	': {
    '& .MuiButtonBase-root': {
      display: 'none',
    },
  },
  '& .MuiDataGrid-cell': {
    paddingLeft: '15px',
    color: 'rgba(0, 0, 0, 0.87)',
  },
};

export const MODE = {
  CREATE: 'create',
  EDIT: 'edit',
};

export const Table_Ordering_Config = {
  DINE_IN_SETTINGS: {
    title: 'table_ordering.dine_in',
    key: 'DINE_IN_SETTINGS',
    expandedPanel: true,
  },
  TAKEOUT_SETTINGS: {
    title: 'table_ordering.takeout',
    key: 'TAKEOUT_SETTINGS',
    expandedPanel: true,
  },
  DELIVERY_SETTINGS: {
    title: 'table_ordering.delivery',
    key: 'DELIVERY_SETTINGS',
    expandedPanel: true,
  },
};
//temporary version need to discuss
export const MAX_ITEM_COUNT_IN_TABLE = 100;
export const MAX_ITEM_COUNT_IN_PRINTER = 200;

export const PADDING_MODAL_ACTION_LEFT = {
  paddingLeft: '24px',
};

export const PADDING_MODAL_ACTION_RIGHT = {
  paddingRight: '24px',
};
