import { useMemo } from 'react';
import { FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import CloseIcon from '@mui/icons-material/Close';
import useProviderConfig from 'hooks/useProviderConfig';
import DialogActions from '@mui/material/DialogActions';
import { PROVIDER_MODAL_STEP } from 'pages/Providers/Providers';
import GrubHubV2Form from 'components/Providers/readOnly/GrubHubV2Form';
import ReadOnlyFooter from 'components/Providers/readOnly/ReadOnlyFooter';
import ReadOnlyContent from 'components/Providers/readOnly/ReadOnlyContent';
import GrubHubV2Footer from 'components/Providers/readOnly/GrubHubV2Footer';
import { Dialog, DialogContent, DialogTitle, IconButton, Box } from '@mui/material';
import DraggableDialogWrapper from 'components/DraggableDialogWrapper/DraggableDialogWrapper';
import useProviderConnectReadOnly, { initialDataModel } from 'components/Providers/hooks/useProviderConnectReadOnly';
const CustomDialogTitle = (props) => {
  const { children, onClose, ...other } = props;
  return (
    <DialogTitle sx={{ ml: 1, p: 2, fontSize: '24px', fontWeight: 400 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 16,
            top: 16,
            color: '#929398',
          }}
        >
          <CloseIcon sx={{ color: '#929398' }} />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};
const ReadOnlyDialog = ({
  open,
  handleClose,
  title,
  providerName,
  cancelTitle,
  saveTitle,
  handleChange,
  value,
  handleContinue,
  currentStep,
  providerSRV,
  setProviderReadOnlyStep,
}) => {
  const { providers } = useProviderConfig();
  const { t } = useTranslation();
  const { formMethods, handleSubmit, loading } = useProviderConnectReadOnly(value, handleClose, providerSRV);

  const isProviderHasSteps = useMemo(() => {
    return providers?.grubhubv2?.srv === providerSRV;
  }, [providers, providerSRV]);

  const handleBack = () => {
    setProviderReadOnlyStep(PROVIDER_MODAL_STEP.READONLY);
    formMethods.reset(initialDataModel);
  };

  const hasDisputeOnlyOption = useMemo(() => {
    return (providers?.ubereats?.srv === providerSRV) || (providers?.doordash?.srv === providerSRV);
  },[providers, providerSRV]);

  const handleSave = () => {
    isProviderHasSteps ? setProviderReadOnlyStep(PROVIDER_MODAL_STEP.CONNECT) : handleContinue();
  };

  return (
    <Dialog
      open={open}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth={true}
      disableEscapeKeyDown={true}
      onClose={handleClose}
      PaperComponent={DraggableDialogWrapper}
      PaperProps={{
        sx: { width: '754px', maxWidth: '100%', minHeight: '580px', background: '#FAFAFA', cursor: 'grab' },
      }}
    >
      <FormProvider {...formMethods}>
        <form onSubmit={formMethods.handleSubmit(handleSubmit)}>
          <Box>
            <CustomDialogTitle id="customized-dialog-title" onClose={handleClose}>
              {`${t(title, { name: providerName })}`}
            </CustomDialogTitle>
            <DialogContent sx={{ width: '100%', cursor: 'initial' }} dividers>
              {isProviderHasSteps && PROVIDER_MODAL_STEP.CONNECT === currentStep ? (
                <GrubHubV2Form />
              ) : (
                <ReadOnlyContent
                  value={value}
                  handleChange={handleChange}
                  providerName={providerName}
                  hasDisputeOnlyOption={hasDisputeOnlyOption}
                />
              )}
            </DialogContent>
          </Box>
          <DialogActions>
            <Box sx={{ margin: '8px 16px' }}>
              {isProviderHasSteps && PROVIDER_MODAL_STEP.CONNECT === currentStep ? (
                <GrubHubV2Footer
                  handleBack={handleBack}
                  disabled={!formMethods.formState.isDirty || !formMethods.formState.isValid}
                  loading={loading}
                />
              ) : (
                <ReadOnlyFooter
                  saveTitle={saveTitle}
                  cancelTitle={cancelTitle}
                  handleClose={handleClose}
                  handleSave={handleSave}
                />
              )}
            </Box>
          </DialogActions>
        </form>
      </FormProvider>
    </Dialog>
  );
};

export default ReadOnlyDialog;
