import { memo } from 'react';
import { Button, Box } from '@mui/material';
import Preloader from 'components/Preloader';
import { useTranslation } from 'react-i18next';
import useTags from 'redesign/Tags/hooks/useTags';
import EmptyMenu from 'components/MenuNew/EmptyMenu';
import { PrinterTitle } from 'redesign/Printers/styles';
import CreateAndEditTag from 'redesign/Tags/CreateAndEditTag';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import { MODE, MUI_TO_SX_STYLES } from 'pages/TableOrdering/config';
import TableOrderModal from 'pages/TableOrdering/Tables/TableOrderModal';
import { HeaderContainer, StripedDataGrid } from 'pages/TableOrdering/styles';

const Tags = () => {

  const  { t } = useTranslation();

  const {
    formMethods,
    handleSubmit,
    mode,
    loading,
    openTagsModal,
    setOpenTagsModal,
    handleCancel,
    setRowsPerPage,
    tagList,
    tagsColumns
  } = useTags()

  return (
    <Box sx={{ mt: 6 }}>
      <HeaderContainer>
        <Button
          id="addPrinter"
          onClick={() => setOpenTagsModal(true)}
          variant="contained"
          color="primary"
          type="button"
          startIcon={<AddOutlinedIcon />}
        >
          {t('Add Tag')}
        </Button>
      </HeaderContainer>
      <TableOrderModal
        open={openTagsModal}
        handleClose={handleCancel}
        title={mode === MODE.CREATE ? t('Add Tag') : t('Edit Tag')}
        disabled={!formMethods.formState.isValid || !formMethods.formState.isDirty}
        saveTitle={mode === MODE.CREATE ? t('Add') : t('Save')}
        cancelTitle={'Cancel'}
        formMethods={formMethods}
        handleSubmit={handleSubmit}
        loading={loading}
      >
        <CreateAndEditTag />
      </TableOrderModal>
      <PrinterTitle>{t('Tags List')}</PrinterTitle>
      <StripedDataGrid
        sx={MUI_TO_SX_STYLES}
        autoHeight={true}
        hideFooterSelectedRowCount={true}
        hideFooter={true}
        isEditable={false}
        rowsPerPageOptions={[200]}
        pageSizeOptions={[200]}
        pageSize={100}
        onPageSizeChange={(newPageSize) => setRowsPerPage(newPageSize)}
        rows={tagList}
        columns={tagsColumns}
        components={{
          LoadingOverlay: Preloader,
          NoRowsOverlay: () => (
            <Box sx={{ mt: '100px' }}>
              <EmptyMenu text={t('There are no tags yet')} />
            </Box>
          ),
        }}
        loading={loading}
      />
    </Box>
  )
}

export default memo(Tags)