import { useEffect, useState } from 'react';
import {
  FormControl,
  Box,
  Select,
  MenuItem,
  Checkbox,
  ListItemText,
  OutlinedInput,
  InputLabel
} from '@mui/material';
import { ORDERS_FILTER_STATUS_HISTORY } from 'constants/config';
import { useTranslation } from 'react-i18next';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  autoFocus: false,
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 240,
    },
  },
};

const StatusesSelect = ({ show, requestData, setRequestData }) => {
  const { t } = useTranslation();

  // 1) Build arrays for easy mapping:
  //    - allStatusValues:     ["unconfirmed", "in_house", "ready_for_pickup", ...]
  //    - statusMap:           { in_house: "processing_", ready_for_pickup: "ready_", ... }
  //      so that we can display the `name` while storing the `value`.
  const allStatusValues = Object.values(ORDERS_FILTER_STATUS_HISTORY).map((item) => item.value);
  const statusMap = Object.values(ORDERS_FILTER_STATUS_HISTORY).reduce((acc, cur) => {
    acc[cur.value] = cur.name; // e.g. { in_house: "processing_" }
    return acc;
  }, {});

  // 2) If localStorage has requestData, use that statuses array directly:
  const currentStatus = JSON.parse(localStorage.getItem('requestData'));
  const initialSelected = currentStatus?.statuses?.length
    ? currentStatus.statuses
    : // Otherwise default to all statuses
      allStatusValues;

  const [selected, setSelected] = useState({
    statuses: initialSelected,
  });

  // 3) Ensure requestData.statuses is in sync on mount:
  useEffect(() => {
    // If requestData has no statuses, put in our selected array:
    if (!requestData.statuses?.length) {
      setRequestData((prev) => ({
        ...prev,
        statuses: [...selected.statuses],
      }));
    }
    // Else if requestData is different than `selected`, adjust `selected`:
    else if (
      JSON.stringify(requestData.statuses) !==
      JSON.stringify(selected.statuses)
    ) {
      setSelected({ statuses: [...requestData.statuses] });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // 4) Handling "select all" and normal multi-select logic:
  const handleChange = (event, child) => {
    const { name, value } = event.target;

    // If user clicked the "checkAll" menu item:
    if (name === 'statuses' && child?.props?.value === 'checkAll') {
      if (selected.statuses.length === allStatusValues.length) {
        // Deselect everything
        setSelected({ statuses: [] });
        setRequestData((prev) => ({ ...prev, statuses: [] }));
      } else {
        // Select everything
        setSelected({ statuses: [...allStatusValues] });
        setRequestData((prev) => ({ ...prev, statuses: [...allStatusValues] }));
      }
      localStorage.setItem(
        'requestData',
        JSON.stringify({ ...currentStatus, ...requestData })
      );
      return;
    }

    // Normal multi-select changes:
    setSelected({ [name]: value });
    setRequestData((prev) => ({ ...prev, statuses: [...value] }));
    localStorage.setItem(
      'requestData',
      JSON.stringify({ ...currentStatus, ...requestData, statuses: [...value] })
    );
  };

  // 5) The displayed value in the Select: show the first label, then “( + n other )”
  const renderSelectedValue = (arr) => {
    if (!arr?.length) return '';
    const firstLabel = t(statusMap[arr[0]]);
    return arr.length > 1
      ? `${firstLabel} (+ ${arr.length - 1} other)`
      : firstLabel;
  };

  return (
    <FormControl
      size="small"
      sx={{ minWidth: show ? 240 : 200, width: show ? 240 : 200, ml: 0, mt: 1 }}
    >
      <Box className="history-status">
        <InputLabel id="statuses">{t('settings.status_*')}</InputLabel>
        <Select
          labelId="statuses"
          id="statuses"
          name="statuses"
          multiple
          value={selected.statuses}
          onChange={handleChange}
          MenuProps={MenuProps}
          sx={{ width: show ? 240 : 200, pl: 1 }}
          input={
            <OutlinedInput
              error={selected?.statuses?.length === 0}
              id="select-statuses"
              placeholder={t('settings.status_*')}
              label={t('settings.status_*')}
            />
          }
          renderValue={renderSelectedValue}
        >
          {/* "Select All" menu item */}
          {allStatusValues.length > 1 && (
            <MenuItem value="checkAll">
              <Checkbox
                name="allCheck"
                checked={selected.statuses.length === allStatusValues.length}
              />
              <ListItemText primary={t('general.all')} />
            </MenuItem>
          )}

          {/* Individual status items */}
          {allStatusValues.map((statusValue) => (
            <MenuItem key={statusValue} value={statusValue}>
              <Checkbox checked={selected.statuses.includes(statusValue)} />
              {/* The label shown to the user */}
              <ListItemText primary={t(statusMap[statusValue])} />
            </MenuItem>
          ))}
        </Select>
      </Box>
      {selected.statuses.length === 0 && (
        <span className="select-error">{t('field_is_required_')}</span>
      )}
    </FormControl>
  );
};

export default StatusesSelect;
