import { memo, useMemo, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import EmptyMenu from 'components/MenuNew/EmptyMenu';
import { ORDER_STATUS } from 'constants/config';
import { getTypeText } from 'services/helpers';
import moment from 'moment-timezone';
import Currency from 'components/Currency';
import { Paper, Box, Typography } from '@mui/material';
import StyledDataGrid from 'components/DataGridTable';
import { getGridStringOperators } from '@mui/x-data-grid';
import useQuery from 'hooks/useQuery';
import { useDispatch, useSelector } from 'react-redux';
import { getOrdersHistory } from 'redux/actions/orders';
import Preloader from 'components/Preloader';
import { useTranslation } from 'react-i18next';
import useProviderConfig from 'hooks/useProviderConfig';

const OrdersHistoryList = ({ data, count, loading, onSelect, setPage, pageTable, setPageSize, pageSize }) => {
  const [rows, setRows] = useState([]);
  const { currentBusiness } = useSelector(({ business }) => business);
  const { providers } = useProviderConfig();
  const history = useHistory();
  const query = useQuery();
  const fieldName = query.get('fieldName');
  const fieldValue = query.get('fieldValue');
  const fieldOperator = query.get('fieldOperator');
  const startDate = query.get('start');
  const endDate = query.get('end');
  const limitQuery = query.get('limit');
  const skipQuery = query.get('skip') || 0;
  const dispatch = useDispatch();
  const tzId = currentBusiness?.timezone ? currentBusiness.timezone?.timeZoneId : 'America/Los_Angeles';
  const body = JSON.parse(localStorage.getItem('requestData'));
  const { t } = useTranslation();

  useEffect(() => {
    if (!data) {
      return;
    }
    const newRowData = data.map((item) => {
      return {
        business: item.business[0]?.companyShortName,
        timeZoneId: item.business[0].timezone.timeZoneId,
        provider: item.provider,
        contactInfo: item.contactInfo.name,
        type: item.type,
        beReadyTime: item.beReadyTime,
        status: item.status.status,
        charges: item.charges.total,
        id: item?.id || item._id,
        _id: item._id,
        number: item.number,
        modified: item.modified,
        isScheduled: item.isScheduled,
      };
    });
    setRows(newRowData);
  }, [data]);

  const columns = useMemo(() => [
    {
      field: 'business',
      headerName: t('orders_history.brand'),
      flex: 1,
      sortable: false,
      filterable: true,
      editable: false,
      disableColumnMenu: false,
      filterOperators: ['contains', 'equals'],
      renderCell: ({ value }) => {
        return <Typography>{value}</Typography>;
      },
    },
    {
      field: 'provider',
      headerName: t('orders_history.provider'),
      sortable: true,
      flex: 1,
      editable: false,
      filterable: false,
      disableColumnMenu: true,
      renderCell: ({ value }) => {
        return <img src={providers[value]?.logoUrl} width="40" height="40" alt="order" className="rounded" />;
      },
    },
    {
      field: 'contactInfo',
      headerName: t('orders_history.client'),
      editable: false,
      flex: 1,
      sortable: false,
      filterable: false,
      renderCell: ({ value, row }) => {
        return (
          <Box sx={{ width: '100%' }}>
            <Typography>{value}</Typography>
            <Typography>{`#${row.number}`}</Typography>
          </Box>
        );
      },
    },
    {
      field: 'type',
      headerName: t('Type'),
      editable: false,
      flex: 1,
      sortable: false,
      filterable: false,
      renderCell: ({ value }) => {
        return <Typography>{getTypeText(value, t)}</Typography>;
      },
    },
    {
      field: 'beReadyTime',
      headerName: t('orders_history.date'),
      editable: false,
      flex: 1,
      sortable: false,
      filterable: false,
      renderCell: ({ value, row }) => {
        const orderTzId = row.timeZoneId;
        return row.status.status !== 'unconfirmed' ? (
          <Box
            className={isLateOrder(moment.utc(value).tz(orderTzId || tzId), row.status.status) ? 'text-blink' : ''}
            sx={{ width: '100%', display: 'flex', flexDirection: 'column' }}
          >
            <Typography sx={{ fontWeight: 700 }}>
              {moment
                .utc(value)
                .tz(orderTzId || tzId)
                ?.format('hh:mm A')}
            </Typography>
            <Typography>
              {moment
                .utc(value)
                .tz(orderTzId || tzId)
                .format('MMM Do')}
            </Typography>
            {row.isScheduled && (
              <Typography>{row.isScheduled && <i className="icon icon-alarm-check text-info ml-2" />}</Typography>
            )}
          </Box>
        ) : (
          <Box
            className={
              isLateOrder(moment.utc(row.beReadyTime).tz(orderTzId || tzId), row.status.status) ? 'text-blink' : ''
            }
            sx={{ width: '100%', display: 'flex', flexDirection: 'column' }}
          >
            <Typography>TBD</Typography>
            <Typography sx={{ fontWeight: 700 }}>
              {moment
                .utc(value)
                .tz(orderTzId || tzId)
                .format('hh:mm A')}
            </Typography>
            <Typography>
              {moment
                .utc(value)
                .tz(orderTzId || tzId)
                .format('MMM Do')}
            </Typography>
            {row.isScheduled && (
              <Typography>{value && <i className="icon icon-alarm-check text-info ml-2" />}</Typography>
            )}
          </Box>
        );
      },
    },
    {
      field: 'status',
      headerName: t('orders_history.status'),
      editable: false,
      flex: 1,
      sortable: false,
      filterable: true,
      renderCell: ({ value, row }) => {
        return <Status value={value} modified={row.modified} />;
      },
    },
    {
      field: 'charges',
      headerName: t('order_view.total'),
      editable: false,
      flex: 1,
      sortable: false,
      filterable: false,
      float: 'right',
      renderCell: ({ value, row }) => {
        return <Currency value={value} />;
      },
    },
  ]);

  if (columns.length > 0) {
    const operators = getGridStringOperators()
      .map((item) => {
        if (item.value === 'contains' || item.value === 'equals') {
          return item;
        }
      })
      .filter((item) => item);

    columns.map((item) => {
      item.filterOperators = [...operators];
    });
  }

  const isLateOrder = (date, status) => {
    return status === 'in_progress'
      ? Number(new Date(date)) < Date.now() + 60000
      : status === 'ready_for_pickup'
        ? Number(new Date(date)) < Date.now() - 60000 * 15
        : false;
  };

  const [filterData, setFilterData] = useState(null);
  const [propsData, setPropsData] = useState({});
  let isPageChange = false;
  const [initialData, setInitialData] = useState({
    filter: {
      filterModel: {
        items: [],
      },
    },
  });

  useEffect(() => {
    if (Number(skipQuery) === 0) {
      setPropsData({
        page: 0,
      });
    }
  }, [skipQuery, body?.skip]);

  useEffect(() => {
    if (filterData?.[0]) {
      const filterName = filterData[0].columnField;
      const filterValue = filterData[0].value ? filterData[0].value : '';
      const filterOperator = filterData[0].operatorValue;
      history.push({
        pathname: history.location.pathname,
        search: `&fieldName=${filterName}${filterValue ? `&fieldValue=${filterValue}` : ''
          }&fieldOperator=${filterOperator}${startDate ? `&start=${startDate}` : ''}${endDate ? `&end=${endDate}` : ''}`,
      });
    }
  }, [filterData]);

  useEffect(() => {
    if (fieldName && fieldValue && fieldOperator) {
      if (!initialData.filter.filterModel?.items?.length) {
        initialData.filter.filterModel.items.push({
          columnField: fieldName,
          operatorValue: fieldOperator,
          value: fieldValue,
        });

        setInitialData({
          ...initialData,
        });
      }
    }
  }, []);
  const onScroll = () => window?.scrollTo({ left: 0, top: 0 });

  const onPageChange = (page) => {
    if (isPageChange) {
      page = 0;
    }
    setPropsData({
      page: page,
    });
    setPage(page);
    const skip = page * body.limit;
    body.skip = skip;
    if (!isPageChange) {
      dispatch(getOrdersHistory(body, page));
      history.push(`${history.location.pathname}?skip=${skip}&limit=${body.limit}&page=${page}`);
    }
    isPageChange = false;
    onScroll();
  };

  const onPageSizeChange = (newPageSize) => {
    setPageSize(newPageSize);
    isPageChange = true;
    body.skip = 0;
    body.limit = newPageSize;
    setPropsData({
      page: 0,
    });
    localStorage.setItem('requestData', JSON.stringify(body));
    dispatch(getOrdersHistory(body, 0, true));
    history.push(`${history.location.pathname}?skip=${0}&limit=${newPageSize}&page=${0}`);
    onScroll();
  };

  return (
    <div className="order-list">
      {loading && <Preloader overlay />}
      {data?.length ? (
        <Paper>
          <Box
            sx={{
              height: 'calc(100vh - 136px)',
              width: '100%',
            }}
          >
            {loading ? (
              <Preloader overlay />
            ) : (
              <StyledDataGrid
                onStateChange={(state) => {
                  setFilterData(state.filter.filterModel.items);
                }}
                initialState={{
                  ...initialData,
                }}
                hideFooterPagination={loading}
                hideFooterSelectedRowCount={loading}
                isEditable={false}
                disableSelectionOnClick={true}
                disableVirtualization={true}
                disableColumnMenu={true}
                rows={loading ? [] : rows}
                rowCount={count}
                columns={loading ? [] : columns}
                rowHeight={110}
                pageSize={pageSize}
                onRowClick={({ row }) => {
                  return onSelect(row);
                }}
                onPageChange={onPageChange}
                onPageSizeChange={onPageSizeChange}
                rowsPerPageOptions={[5, 10, 15, 50, 100]}
                pagination
                {...propsData}
              />
            )}
          </Box>
        </Paper>
      ) : !loading ? (
        // </div>
        <Box sx={{ mt: '100px' }}>
          <EmptyMenu text={t('orders_history.there_are_orders_yet')} />
        </Box>
      ) : (
        <></>
      )}
    </div>
  );
};

const Status = ({ value, modified }) => {
  const { t } = useTranslation();
  const status = ORDER_STATUS[value];
  return (
    <>
      <Box display="flex" alignItems="center">
        {!status ? (
          <p>{value}</p>
        ) : (
          <Box display="flex" flexDirection="column">
            <Box display="flex">
              {status.icon ? (
                <i className={`icon  ${status.icon}`} style={{ color: status.color }}></i>
              ) : (
                <div className="status-icon" style={{ backgroundColor: status.color }} />
              )}
              <Typography>{t(status.name)}</Typography>
            </Box>
            <Typography align="center" color="error">
              {modified && t('orders_history.modified_')}
            </Typography>
          </Box>
        )}
      </Box>
    </>
  );
};

export default memo(OrdersHistoryList);
