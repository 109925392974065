import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { getDeliveryDetailsByOrderId } from 'redux/actions/orders';

export const DISPATCH_BTN_ENUM = {
  SHOW_MORE: 'ORDER DELIVERY DETAILS',
  SHOW_LESS: 'SHOW LESS',
};

export const DISPATCH_DELIVERY_CARRIER = {
  DISPATCH: 'DISPATCH',
};

const useDeliveryDispatchDetails = (orderId) => {
  const dispatch = useDispatch();
  const [btnName, setBtnName] = useState(DISPATCH_BTN_ENUM.SHOW_MORE);
  const [showDetails, setShowDetails] = useState(false);
  const [loadingDeliveryDetails, setLoadingDeliveryDetails] = useState(false);
  const [deliveryDetailsData, setDeliveryDetailsData] = useState({
    providerName: '',
    note: '-',
    phoneNumber: '-',
    trackingUrl: '',
    refCode: '-'
  });

  const handleCloseDetails = () => {
    setLoadingDeliveryDetails(false);
    setShowDetails(false);
    setBtnName(DISPATCH_BTN_ENUM.SHOW_MORE);
    setDeliveryDetailsData({
      providerName: '',
      note: '-',
      phoneNumber: '-',
      trackingUrl: '',
      refCode: '-'
    });
  };

  useEffect(() => {
    handleCloseDetails();
  }, [orderId]);

  const handleClick = () => {
    setLoadingDeliveryDetails(true);
    dispatch(getDeliveryDetailsByOrderId(orderId))
      .then((res) => {
        const details = res?.data?.data;
        setDeliveryDetailsData({
          providerName: details?.providerServiceName || '',
          note: details?.note || '-',
          phoneNumber: details?.taskDetails?.courier?.phoneNumber || '-',
          trackingUrl: details?.taskDetails?.courier?.trackingUrl || '',
          refCode: details?.taskDetails?.refCode || '-'
        });
        setShowDetails(true);
        setBtnName(DISPATCH_BTN_ENUM.SHOW_LESS);
      })
      .catch(() => {})
      .finally(() => setLoadingDeliveryDetails(false));
  };

  return {
    btnName,
    loadingDeliveryDetails,
    deliveryDetailsData,
    handleClick,
    showDetails,
    handleCloseDetails,
  };
};

export default useDeliveryDispatchDetails;
