import { styled } from '@mui/material/styles';
import { Typography, Link, Box } from '@mui/material';
import { LoadingButton } from '@mui/lab';

export const StyledTypographyItem = styled(Typography)(({ theme }) => {
  return {
    padding: theme.spacing(2),
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '150%',
    letterSpacing: '0.15px',
    color: 'rgba(0, 0, 0, 0.87)',
    cursor: 'pointer',
  };
});

export const StyledModalTitle = styled(Typography)(({ theme }) => {
  return {
    fontSize: '24px',
    fontWeight: 400,
    lineHeight: '32px',
    letterSpacing: '0.15px',
    color: '#1D1B20',
  };
});

export const StyledReasonTitle = styled(Typography)(({ theme }) => {
  return {
    color: '#49454F',
    fontSize: '15px',
    fontWeight: 400,
    lineHeight: '22px',
    letterSpacing: '0.25px',
  };
});

export const StyledAction = styled(Typography)(({ theme }) => {
  return {
    display: 'flex',
    textAlign: 'center',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: theme.spacing(2),
    gap: '16px',
  };
});

export const StyledShowMoreLessButton = styled(LoadingButton)(({ theme }) => {
  return {
    width: '100%',
    height: '36px',
    marginBottom: theme.spacing(2),
    color: '#626A7A',
    fontSize: 14,
    fontWeight: 500,
    border: '1px solid rgba(98, 106, 122, 0.50)',
    cursor: 'pointer',
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: '#F7F8F8',
      border: '1px solid rgba(98, 106, 122, 0.50)',
    },
  };
});

export const StyledShowMoreLessText = styled(Typography)(({ theme }) => {
  return {
    color: '#2C405A',
    fontSize: 16,
    fontWeight: 400,
    lineHeight: '22px',
  };
});

export const StyledShowMoreLessLink = styled(Link)(({ theme }) => {
  return {
    fontSize: 16,
    fontWeight: 400,
    cursor: 'pointer',
  };
});

export const StyledImageWrapper = styled(Box)(() => {
  return {
    width: '98px',
    height: '98px',
    '> img': {
      width: '100%',
      height: '100%',
    }
  };
});