import { styled, Box } from '@mui/material';

export const ManualDoordashWrapper = styled(Box)({
  display: 'flex',
  alignItems: 'center'
});

export const ManualDoordashBox = styled(Box)({
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'flex-end',
  flexDirection: 'column',
});

export const ManualDoordashContent = styled(Box)(({ theme }) => ({
  width: '480px',
  marginLeft: theme.spacing(23)
}));