import { useTranslation } from 'react-i18next';
import { priceTransform } from 'helpers/formPrice';
import { Grid, InputAdornment, Tooltip } from '@mui/material';
import CustomTextInput from 'components/Forms/CustomTextInput';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';


const DeliveryLimits = ({ websiteFormMethods }) => {
  const { t } = useTranslation();

  const handleChange = (event) => {
    const value = priceTransform(event.target.value);
    websiteFormMethods.setValue('deliveryLimits.maxDeliveryFee', value, { shouldDirty: true });
  };

  return (
    <Grid container sx={{ mb: 2 }} spacing={2}>
      <Grid item>
        <CustomTextInput
          name={`deliveryLimits.maxDeliveryRadius`}
          label={'Max Delivery Radius'}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <Tooltip title={t('Set the maximum delivery radius')}>
                  <HelpOutlineIcon fontSize={'small'} />
                </Tooltip>
              </InputAdornment>
            ),
          }}
          sx={{ width: '150px' }}
          inputProps={{ type: 'number' }}
        />
      </Grid>
      <Grid item>
        <CustomTextInput
          name={`deliveryLimits.maxDeliveryFee`}
          label={'Max Delivery Fee'}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <Tooltip title={t('The maximum delivery fee within the defined radius')}>
                  <HelpOutlineIcon fontSize={'small'} />
                </Tooltip>
              </InputAdornment>
            ),
          }}
          onChange={handleChange}
          inputProps={{ type: 'number' }}
          sx={{ width: '150px' }}
        />
      </Grid>
    </Grid>
  )
}

export default DeliveryLimits