import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import { Typography } from '@mui/material';

export const StyledBox = styled(Box)(({ theme }) => {
  return {
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
    padding: `${theme.spacing(2)} 0 ${theme.spacing(3)} 0`,
    svg: {
      color: 'white',
      fontSize: '48px',
    },
    button: {
      background: theme.palette.primary.main,
      color: 'white',
      width: '64px',
      height: '64px',
      '&:hover': {
        background: theme.palette.primary.light,
      },
      '&.Mui-disabled': {
        backgroundColor: theme.palette.text.disabled,
      },
    },
  };
});

export const StyledTitleTableOrdering = styled(Typography)(() => {
  return {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    maxWidth: '150px',
  };
});

export const StyledTitleShortLink = styled(Typography)(() => {
  return {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    maxWidth: '250px',
  };
});

export const CopiedMessageShortLink = styled(Box)(({ theme }) => ({
  position: 'absolute',
  bottom: '20px',
  right: '48px',
  top: '-18px',
  marginTop: '8px',
  background: theme.palette.secondary.main,
  padding: '4px 12px',
  borderRadius: '6px',
}));