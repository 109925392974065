import AddIcon from '@mui/icons-material/Add';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { ButtonWrap } from './styles';
import { DEFAULT_DELIVERY_RATE_SETTINGS } from 'constants/website';

const ActionsFlat = ({ indexItem, flatFeeFields, id }) => {
  const onAdd = () => {
    if (DEFAULT_DELIVERY_RATE_SETTINGS) {
      flatFeeFields.append(DEFAULT_DELIVERY_RATE_SETTINGS);
    }
  };

  const onDelete = (index) => {
    flatFeeFields.remove(index);
  };

  return indexItem === 0 ? (
    <ButtonWrap id="productFormAddIcon" onClick={onAdd}>
      <AddIcon />
    </ButtonWrap>
  ) : (
    <ButtonWrap id="productFormDeleteIcon" onClick={() => onDelete(indexItem)}>
      <DeleteOutlineIcon />
    </ButtonWrap>
  );
};

export default ActionsFlat;
