import { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import '../../styles/pages/_filter.scss';
import { useDispatch } from 'react-redux';
import {
  COLLECT_BRAND_BUSINESS_IDS_FOR_REPORT,
  SET_CURRENT_SELECT_BRANDS,
  SET_ALL_SELECTED_BRANDS,
  SET_SELECTED_BRANDS,
  ALL_BRANDS_DEPENDS_ON_BUSINESS_GROUP,
} from 'redux/actions/types';
import Tooltip from '@mui/material/Tooltip';
import * as all from 'constants/permissions';
import { useTranslation } from 'react-i18next';
import usePermission from 'hooks/permission';
import { LabelName } from 'pages/Report/styles';
import dateIssue from 'assets/icons/dateIssue.svg';
import { MultiSelect } from 'react-multi-select-component';
import useBrandMultiSelect from 'hooks/useBrandMultiSelect';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

export function CustomBrandAutoCompleteReport({
  convertedBrand,
  currentSelectedBrands = [],
  type = ''
}) {
  const dispatch = useDispatch();
  const checkPermission = usePermission();
  const { t } = useTranslation();
  const {
    options,
    sortOptions,
    selectedBrands,
  } = useBrandMultiSelect(type);
  const [isError, setIsError] = useState(false);

  const setData = (option) => {
    const idsBrandReport = [];

    option.forEach((item) => {
      if (item?.value) {
        return idsBrandReport.push(item?.value?._id);
      }
    });

    dispatch({ type: SET_ALL_SELECTED_BRANDS, payload: option });
    dispatch({ type: COLLECT_BRAND_BUSINESS_IDS_FOR_REPORT, payload: idsBrandReport });
  };

  const handleMultiSelectChange = (option) => {
    setIsError(option.length === 0);
    dispatch({ type: SET_SELECTED_BRANDS, payload: option });
    setData(option);
    dispatch({ type: SET_CURRENT_SELECT_BRANDS, payload: option });
  };

  const customValueRenderer = (selected) => {
    if (selected.length) {
      const name = selected[0]?.name;
      const extractName = name.length > 15 ? `${name.slice(0, 15)}...` : name;
      if (selected?.length > 1) {
        return `${extractName} (+${selected?.length - 1} Other)`;
      } else return extractName;
    }
  };

  useEffect(() => {
    if (convertedBrand.length) {
      const isSuper = checkPermission(all['SUPER']);
      if (currentSelectedBrands.length > 0) {
        const ids = JSON.parse(localStorage.getItem('requestData'));
        const filterItems = currentSelectedBrands.filter((brand) => {
          return ids?.brandIds.includes(brand?.value?._id);
        });
        dispatch({ type: SET_SELECTED_BRANDS, payload: isSuper ? [] : filterItems });
        dispatch({ type: ALL_BRANDS_DEPENDS_ON_BUSINESS_GROUP, payload: filterItems });
        isSuper ? setData([]) : setData(filterItems);
        return;
      }
      isSuper ? setData([]) : setData(convertedBrand);
      dispatch({ type: SET_SELECTED_BRANDS, payload: isSuper ? [] : convertedBrand });
      dispatch({ type: SET_CURRENT_SELECT_BRANDS, payload: convertedBrand });
      dispatch({ type: ALL_BRANDS_DEPENDS_ON_BUSINESS_GROUP, payload: convertedBrand });
    }
  }, [convertedBrand]);

  useEffect(() => {
    setIsError(selectedBrands.length === 0);
  }, [selectedBrands]);

  return (
    <Box
      className={`${isError ? 'custom-brand-autocomplete-error' : ''}`}
      id="custom-brand-autocomplete"
      sx={{ width: '300px', marginTop: '20px', position: 'relative' }}
    >
      <LabelName
        sx={{
          color: isError ? '#E21508 !important' : '',
          span: {
            color: '#E21508 !important',
          },
        }}
      >
        All Brands <span>*</span>
      </LabelName>
      {isError ? (
        <Tooltip title={t('help.field_is_required')} placement="top" arrow>
          <img
            width={16}
            height={16}
            alt="Date Issue Icon"
            src={dateIssue}
            style={{ top: '12px', position: 'absolute', zIndex: 1, left: '13.5px' }}
          />
        </Tooltip>
      ) : null}
      <MultiSelect
        options={options}
        hasSelectAll={!checkPermission(all['SUPER'])}
        value={selectedBrands}
        debounceDuration={300}
        ArrowRenderer={() => <ArrowDropDownIcon color="action" />}
        valueRenderer={customValueRenderer}
        onChange={handleMultiSelectChange}
        labelledBy={t('reports_settings.all_brands_...')}
        onMenuToggle={(isOpen) => {
          !isOpen && sortOptions();
        }}
      />
    </Box>
  );
}
