import { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { LoadingButton } from '@mui/lab';
import {
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  DialogTitle,
  Grid,
  MenuItem,
  InputAdornment,
  FormControl,
  Box,
  IconButton,
  Tooltip,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import Preloader from 'components/Preloader';
import CustomTextInput from 'components/Forms/CustomTextInput';
import CustomCheckbox from 'components/Forms/CustomCheckbox';
import LabelRequired from 'components/FormFields/LabelRequired';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import DraggableDialogWrapper from 'components/DraggableDialogWrapper/DraggableDialogWrapper';
import { MarketingTypes } from './types';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import dateIssue from 'assets/icons/dateIssue.svg';
import { useSelector } from 'react-redux';
import { selectCurrentBusiness } from 'redux/selectors';
import { DEFAULT_TIMEZONE } from 'components/Chargeback/constants';

const initialDataModel = {
  couponCode: '',
  couponName: '',
  couponTitle: '',
  active: true,
  startDate: new Date(),
  endDate: new Date(),
  discountType: 'percent',
  discount: null,
  usePerCustomer: null,
  limitPerCoupon: {
    maxUse: null,
  },
  freeDelivery: false,
  business: '63568016bfe02d6c702f612d',
  useForMarketing: true,
};

const discountTypeEnum = {
  percent: 'percent',
  amount: 'amount',
};

// const invalidDate = (data) => {
//   const date = moment(moment(data), 'DD/MM/YYYY', true);
//   return !data || !date.isValid();
// };

const CustomDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

CustomDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};


const AddEditModal = ({ data, onConfirm, onCancel, showModal, loading }) => {
  const currentBusiness = useSelector(selectCurrentBusiness);

  const { t } = useTranslation();
  const discountTypes = [
    { id: 1, name: 'percent', label: t('website_settings.percent') },
    { id: 1, name: 'amount', label: t('website_settings.amount') },
  ];
  const timezoneId = currentBusiness?.timezone?.timeZoneId || DEFAULT_TIMEZONE;

  dayjs.extend(utc);
  dayjs.extend(timezone);
  dayjs.tz.setDefault(timezoneId || DEFAULT_TIMEZONE);

  const start = dayjs(new Date()).tz(timezoneId)['$d'];
  let currentEndDate = dayjs(new Date()).tz(timezoneId).add(1, 'year')['$d'];

  const [startDate, setStartDate] = useState(start);
  const [startDateError, setStartDateError] = useState('');
  const [endDate, setEndDate] = useState(currentEndDate);
  const [endDateError, setEndDateError] = useState('');

  const editMode = useMemo(() => {
    return !!data?.id;
  }, [data]);

  const formSchema = yup.object().shape({
    couponName: yup.string().required('field_is_required_'),
    couponTitle: yup.string(),
    couponCode: yup
      .string()
      .test('len', 'requires_min_4_character', (val) => !(val.length < 4))
      .matches(/^[a-zA-Z0-9]+$/, 'only_letters')
      .required('field_is_required_'),
    discount: yup
      .number()
      .min(0, 'this_must_be_greater_0')
      .transform((value) => (isNaN(value) ? null : value))
      .typeError('field_is_required_')
      .required('field_is_required_'),
    limitPerCoupon: yup.object().shape({
      maxUse: yup
        .number()
        .min(1, 'this_field_equal_to_1')
        .transform((value) => (isNaN(value) ? null : value))
        .nullable(),
    }),
    usePerCustomer: yup
      .number()
      .min(1, 'this_field_equal_to_1')
      .transform((value) => (isNaN(value) ? null : value))
      .nullable(),
  });
  const formMethods = useForm({
    mode: 'all',
    defaultValues: data || initialDataModel,
    resolver: yupResolver(formSchema),
  });

  const { reset, watch } = formMethods;
  const discountType = watch('discountType');
  const typeLabelSign = useMemo(() => {
    if (discountType === 'percent') {
      return '%';
    }
    return '$';
  }, [discountType]);

  const handleValidateDates = (startDate, endDate) => {
    const start = startDate ? dayjs(startDate).tz(timezoneId) : null;
    const end = endDate ? dayjs(endDate).tz(timezoneId) : null;

    if (!start) {
      setStartDateError(t('field_is_required'));
    } else if (end && start?.isAfter(end, 'day')) {
      setStartDateError('The start date cannot be after the end date');
    } else {
      setStartDateError('');
    }

    if (!end) {
      setEndDateError(t('field_is_required'));
    } else if (start && end?.isBefore(start, 'day')) {
      setEndDateError('The end date cannot be before the start date');
    } else {
      setEndDateError('');
    }
  };


  useEffect(() => {
    const start = startDate ? dayjs(startDate).tz(timezoneId) : null;
    const end = endDate ? dayjs(endDate).tz(timezoneId) : null;
    if (!start) {
      setStartDateError(t('field_is_required'));
    } else if (start && end && start?.isAfter(end, 'day')) {
      setStartDateError('The start date cannot be after the end date');
    } else {
      setStartDateError('');
    }

    if (!end) {
      setEndDateError(t('field_is_required'));
    } else if (end && start && end?.isBefore(start, 'day')) {
      setEndDateError('The end date cannot be before the start date');
    } else {
      setEndDateError('');
    }
  }, [startDate, endDate, timezoneId]);


  useEffect(() => {
    const timezoneId = currentBusiness?.timezone?.timeZoneId || DEFAULT_TIMEZONE;

    const dayjsStart = data?.startDate
      ? dayjs(data?.startDate)['$d']
      : dayjs(new Date()).tz(timezoneId)['$d'];

    const dayjsEnd = data?.endDate
      ? dayjs(data?.endDate)['$d']
      : dayjs(new Date()).tz(timezoneId).add(1, 'year')['$d'];

    reset(data);
    setStartDate(dayjsStart);
    setEndDate(dayjsEnd);
  }, [data, currentBusiness, reset]);

  const onChangeDatePickerStart = (value) => {
    setStartDate(value);
  };

  const onChangeDatePickerEnd = (value) => {
    setEndDate(value);
  };

  const handleSubmit = (values) => {
    if (startDateError || endDateError || handleValidateDates(values.startDate, values.endDate)) return;
    values['type'] = values.useForMarketing ? MarketingTypes.MARKETING : MarketingTypes.SYSTEM;
    const dataModel = {
      ...values,
      [values.discountType]: values.discount,
      startDate: startDate,
      endDate: endDate,
    };
    if (values.discountType === discountTypeEnum.amount) {
      dataModel[discountTypeEnum.percent] = 0;
    } else {
      dataModel[discountTypeEnum.amount] = 0;
    }
    delete dataModel.discountType;
    delete dataModel.discount;

    if (!dataModel.usePerCustomer) {
      delete dataModel.usePerCustomer;
    }
    if (!dataModel?.limitPerCoupon?.maxUse) {
      delete dataModel.limitPerCoupon;
    }

    onConfirm(dataModel);
  };

  return (
    <Dialog
      open={showModal}
      disableEscapeKeyDown={true}
      onClose={() => {}}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth={true}
      PaperComponent={DraggableDialogWrapper}
      PaperProps={{ sx: { width: '763px', maxWidth: '100%', background: '#FAFAFA', p: 2 } }}
    >
      {loading && <Preloader />}
      <FormProvider {...formMethods}>
        <form onSubmit={formMethods.handleSubmit(handleSubmit)}>
          <CustomDialogTitle id="customized-dialog-title" onClose={onCancel}>
            {editMode ? t('website_settings.update_the_coupon') : t('website_settings.add_new_coupon')}
          </CustomDialogTitle>
          <DialogContent sx={{ width: '100%' }}>
            <Grid container spacing={2} sx={{ mt: 1 }}>
              <Grid item xs={6} sx={{ height: '72px' }}>
                <CustomTextInput
                  label={<LabelRequired>{t('website_settings.coupon_name')}</LabelRequired>}
                  name="couponName"
                  sx={{ width: '100%' }}
                />
              </Grid>
              <Grid item xs={6} sx={{ height: '72px' }}>
                <CustomTextInput label={t('website_settings.title')} name="couponTitle" sx={{ width: '100%' }} />
              </Grid>
              <Grid item xs={6} sx={{ height: '72px' }}>
                <CustomTextInput
                  label={<LabelRequired>{t('website_settings.coupone_code')}</LabelRequired>}
                  name="couponCode"
                  sx={{ width: '100%', mt: 1 }}
                />
              </Grid>
              <Grid item xs={3} sx={{ height: '72px' }}>
                <CustomTextInput
                  sx={{ width: '100%', mt: 1 }}
                  select={true}
                  name="discountType"
                  label={<LabelRequired>{t('website_settings.discount_type')}</LabelRequired>}
                >
                  {discountTypes.map((option) => (
                    <MenuItem key={option.id} value={option.name}>
                      {option.label}
                    </MenuItem>
                  ))}
                </CustomTextInput>
              </Grid>
              <Grid item xs={3} sx={{ height: '72px' }}>
                <CustomTextInput
                  InputProps={{
                    startAdornment: <InputAdornment position="start">{typeLabelSign}</InputAdornment>,
                  }}
                  inputProps={{
                    type: 'number',
                  }}
                  label={<LabelRequired>{t('order_view.discount')}</LabelRequired>}
                  name="discount"
                  sx={{ width: '100%', mt: 1 }}
                />
              </Grid>
              <Grid item xs={12} sx={{ height: '72px' }}>
                <FormControl sx={{ width: '100%' }}>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <Box
                      sx={{
                        display: 'flex',
                        gap: '16px',
                      }}
                    >
                      <FormControl sx={{ width: '50%' }}>
                        <Box id="start-date-wrapper" className="pl-0">
                          {startDateError && (
                            <Tooltip title={startDateError} placement="top" arrow>
                              <img
                                width={16}
                                height={16}
                                alt="Date Issue Icon"
                                src={dateIssue}
                                style={{ top: '12px', position: 'absolute', zIndex: 1, left: '13.5px' }}
                              />
                            </Tooltip>
                          )}
                          <DatePicker
                            sx={{
                              width: '100%',
                              input: { padding: `8px 0 8px ${startDateError ? '35px' : '12px'}` },
                              fieldset: { borderColor: startDateError ? '#E21508 !important' : '' },
                            }}
                            name="start_date"
                            value={startDate}
                            onChange={onChangeDatePickerStart}
                            label={
                              <span
                                style={{ backgroundColor: '#fafafa' }}
                                className={`${startDateError ? 'text-danger' : ''}`}
                              >
                                {t('website_settings.start_date')}
                              </span>
                            }
                          />
                        </Box>
                      </FormControl>
                      <FormControl sx={{ width: '50%' }}>
                        <Box id="end-date-wrapper" className="pl-0">
                          {endDateError && (
                            <Tooltip title={endDateError} placement="top" arrow>
                              <img
                                width={16}
                                height={16}
                                alt="Date Issue Icon"
                                src={dateIssue}
                                style={{ top: '12px', position: 'absolute', zIndex: 1, left: '13.5px' }}
                              />
                            </Tooltip>
                          )}
                          <DatePicker
                            sx={{
                              width: '100%',
                              input: { padding: `8px 0 8px ${endDateError ? '35px' : '12px'}` },
                              fieldset: { borderColor: endDateError ? '#E21508 !important' : '' },
                            }}
                            name="end_date"
                            value={endDate}
                            onChange={onChangeDatePickerEnd}
                            label={
                              <span
                                style={{ backgroundColor: '#fafafa' }}
                                className={`${endDateError ? 'text-danger' : ''}`}
                              >
                                {t('website_settings.end_date')}
                              </span>
                            }
                          />
                        </Box>
                      </FormControl>
                    </Box>
                  </LocalizationProvider>
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <CustomTextInput
                  label={t('website_settings.max_use')}
                  name="limitPerCoupon.maxUse"
                  inputProps={{
                    type: 'number',
                  }}
                  sx={{ width: '100%', mt: 1 }}
                />
              </Grid>
              <Grid item xs={6}>
                <CustomTextInput
                  inputProps={{
                    type: 'number',
                  }}
                  label={t('website_settings.max_use_customer')}
                  name="usePerCustomer"
                  sx={{ width: '100%', mt: 1 }}
                />
              </Grid>
              <Grid item xs={12}>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: 'max-content',
                    '>label': {
                      width: 'max-content',
                    },
                  }}
                >
                  <CustomCheckbox
                    sx={{ '& .MuiSvgIcon-root': { fontSize: 20 } }}
                    label={t('website_settings.offer_coupon')}
                    name="freeDelivery"
                  />
                  <CustomCheckbox
                    sx={{ '& .MuiSvgIcon-root': { fontSize: 20 } }}
                    disabled={
                      !(moment().format('DD-MM-YYYY') === moment(endDate).format('DD-MM-YYYY')) &&
                      moment(endDate).isBefore(moment())
                    }
                    label={t('settings.active')}
                    name="active"
                  />
                  <CustomCheckbox
                    sx={{ '& .MuiSvgIcon-root': { fontSize: 20 } }}
                    checked={false}
                    disabled={
                      !(moment().format('DD-MM-YYYY') === moment(endDate).format('DD-MM-YYYY')) &&
                      moment(endDate).isBefore(moment())
                    }
                    label={t('website_settings.available_for_marketing_campaign')}
                    name="useForMarketing"
                  />
                </Box>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions sx={{ cursor: 'grab' }}>
            <Button id="ordersCancel" variant="text" sx={{ mr: 2, color: 'black' }} color="primary" onClick={onCancel}>
              {t('orders.cancel')}
            </Button>
            <LoadingButton type="submit" variant="contained" loading={false}>
              {editMode ? t('website_settings.update') : t('settings.save')}
            </LoadingButton>
          </DialogActions>
        </form>
      </FormProvider>
    </Dialog>
  );
};

AddEditModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  data: PropTypes.object,
};

AddEditModal.defaultProps = {
  data: null,
  showModal: false,
  loading: false,
  onConfirm: () => {},
  onCancel: () => {},
};

export default AddEditModal;
