import styled from '@emotion/styled';
import { Typography, Box, Chip } from '@mui/material';

export const PrinterTitle = styled(Typography)({
  color: 'rgba(60, 67, 80, 1);',
  fontSize: '22px',
  fontWeight: 400,
  lineHeight: '28px',
  paddingBottom: '12px',
  marginTop: '16px',
});

export const PrinterStatusBlock = styled(Box)({
  border: '1px solid rgba(0, 0, 0, 0.24)',
  borderRadius: '100px',
  display: 'inline-block',
});

export const PrinterStatus = styled(Typography)({
  border: '1px solid rgba(0, 0, 0, 0.24)',
  borderRadius: '100px',
  display: 'flex',
  gap: '4px',
  padding: '3px 12px',
  alignItems: 'center',
});

export const PrinterStatusTitle = styled(Typography)({
  fontSize: '13px',
  lineHeight: '18px',
});

export const PrinterTicketStyle = styled(Typography)({
  textTransform: 'capitalize',
  fontSize: '14px',
  lineHeight: '20px',
  letterSpacing: '0.17px'
});

export const TagsItem = styled(Chip)({
  color: 'rgba(0, 0, 0, 0.87)',
  fontSize: '14px',
  lineHeight: '18px',
  height: '26px'
});

export const TagsItemWrapper = styled(Box)(({theme}) => ({
  margin: theme.spacing(2)
}));