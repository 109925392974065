import { useContext, useEffect, useState } from 'react';
import CreateBrand from './CreateBrand';
import BrandSuccess from './BrandSuccess';
import { Typography } from '@mui/material';
import { handleDeleteValues } from 'utils/soldOut';
import { createBrand } from 'redux/actions/userAuthV2';
import { getBusinessSettings } from 'redux/actions/business';
import { SIGN_UP_FLOW_ADD_BRAND } from 'constants/availableButtons';
import instance from 'services/api';
import { PaymentPeriodicityContext } from 'context/paymentPeriodicityContext';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

const BrandSignUp = (props) => {
  const [active, setActive] = useState(SIGN_UP_FLOW_ADD_BRAND.ADD_BRAND);
  const [loading, setLoading] = useState(false);
  const location = useLocation();

  const searchParams = new URLSearchParams(location.search);
  const isSpecialBrand = searchParams.get('isSpecialBrand');

  const [selectedPlanPrice, getSelectedPlanPrice] = useState('');
  const [sendDataInfo, handleSendDataInfo] = useState(
    {
      metadata: { communicationEmail: '', place_id: '' },
      cloneMenu: true,
      businessId: JSON.parse(localStorage.getItem('businessInfo'))?.id || '',
    }
  );
  const [selectedPlan, handleSelectedPlan] = useState('');
  const [currentBusiness, handleCurrentBusiness] = useState(null);
  const [currentBrandId, setCurrentBrandId] = useState('');
  const [data, setData] = useState([]);
  const { periodicity } = useContext(PaymentPeriodicityContext);
  const { t } = useTranslation();

  useEffect(() => {
    getBusinessSettings().then((data) => {
      let brandObject = {};
      if (data?.metadata?.place_id) {
        brandObject = {
          addressInfo: data.addressInfo,
          metadata: { communicationEmail: data?.metadata?.communicationEmail, place_id: data?.metadata?.place_id },
        };
      } else {
        brandObject = {
          addressInfo: data.addressInfo,
          metadata: {
            communicationEmail: data?.metadata?.communicationEmail,
            addressPlaceId: data?.metadata?.addressPlaceId,
          },
        };
      }
      brandObject['cloneMenu'] = true;
      brandObject['businessId'] = JSON.parse(localStorage.getItem('businessInfo'))?.id || '';
      handleSendDataInfo(brandObject);
      handleCurrentBusiness(data);
    });
  }, [props.data]);

  useEffect(() => {
    instance
      .get(`/brand/all?isServicingBrand=1`)
      .then((res) => {
        setLoading(true);
        setData(res.data.data);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const getAddressFields = (addressInfo) => {
    handleSendDataInfo({ ...sendDataInfo, addressInfo });
  };

  const handleSubmit = (values, activeStep) => {
    const location = JSON.parse(localStorage.getItem('selectedLocation'));
    const brand = JSON.parse(localStorage.getItem('selectedBrand'));
    const userEmail = localStorage.getItem('email');
    setLoading(true);
    values.addressInfo = sendDataInfo.addressInfo;
    values.addressInfo.street2 = values.address2;
    values.plan = selectedPlan;
    values.addressInfo.placeId = sendDataInfo?.addressInfo?.placeId || sendDataInfo?.metadata?.place_id || '';
    values.brandId = currentBrandId;
    values.companyName = values.brandName;
    values.locationId = location?.value?._id || brand?.value?.locations[0]?._id;
    values.paymentTerm = periodicity;
    values.metadata['addressPlaceId'] = values.addressPlaceId || '';
    values['businessEmail'] = userEmail || '';
    let modifyValue = handleDeleteValues(values);
    return createBrand(modifyValue)
      .then(() => {
        setActive(activeStep);
      })
      .catch(() => {
        localStorage.removeItem('currentPlan');
      })
      .finally(() => {
        setLoading(false);
        setCurrentBrandId('');
      });
  };
  return (
    <div className="signup-wizard my-5">
      {active === SIGN_UP_FLOW_ADD_BRAND.ADD_BRAND && (
        <div className="wizard-header">
          <Typography sx={{ fontSize: '32px', fontWeight: 700, color: '#000000' }}>
            {t('sign_up.welcome_to_Orders.co')}
          </Typography>
          <Typography component={'p'} sx={{ fontSize: '16px', fontWeight: 400, color: '#000000' }}>
            {t('sign_up.you_are_adding_a_new_brand_for_your_location')}
          </Typography>
        </div>
      )}
      {active === SIGN_UP_FLOW_ADD_BRAND.ADD_BRAND && (
        <CreateBrand
          sendDataInfo={sendDataInfo}
          address={currentBusiness?.addressInfo.name || currentBusiness?.address}
          address2={currentBusiness?.address2}
          loading={loading}
          getAddressFields={getAddressFields}
          handleSelectedPlan={handleSelectedPlan}
          selectedPlan={selectedPlan}
          getSelectedPlanPrice={getSelectedPlanPrice}
          selectedPlanPrice={selectedPlanPrice}
          setActive={setActive}
          handleSubmit={handleSubmit}
          brands={data}
          setData={setData}
          setCurrentBrandId={setCurrentBrandId}
          isSpecialBrand={isSpecialBrand}
        />
      )}
      {active === SIGN_UP_FLOW_ADD_BRAND.ADD_BRAND_SUCCESS && <BrandSuccess />}
    </div>
  );
};

export default BrandSignUp;
