import { toast } from 'react-toastify';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { cancelDeliveryOrderDispatch } from 'redux/actions/orders';

const defaultState = {
  reason: '',
  comment: '',
};
export const formCancelSchema = yup.object().shape({
  cancellationReason: yup.string().trim(),
  comment: yup.string().required('field_is_required_').trim(),
});

export const useDeliveryCancel = (cancelDeliveryModal, data, closeDeliveryCancelModal) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const formDeliveryCancelMethods = useForm({
    mode: 'all',
    defaultValues: defaultState,
    resolver: yupResolver(formCancelSchema),
  });

  useEffect(() => {
    if (!cancelDeliveryModal) {
      formDeliveryCancelMethods.clearErrors();
      formDeliveryCancelMethods.reset(defaultState);
    }
  }, [cancelDeliveryModal]);

  const handleSubmit = (values) => {
    setLoading(true);
    const restaurantMailbox = data?.business[0]?.restaurantMailbox;
    const orderId = data?._id || data?.id;
    return dispatch(
      cancelDeliveryOrderDispatch(orderId, {
        ...values,
        orderNumber: data?.number || '',
        restaurantMailbox: restaurantMailbox || '',
      })
    )
      .then(() => {
        setLoading(false);
        closeDeliveryCancelModal();
        toast.success('Delivery successfully canceled');
      })
      .catch((e) => {
        setLoading(false);
        if (Array.isArray(e?.response?.data?.message)) {
          toast.error(e?.response?.data?.message[0] || 'Delivery canceled failed!!!');
        } else {
          toast.error(e?.response?.data?.message || 'Delivery canceled failed!!!');
        }
      })
      .finally(() => setLoading(false));
  };

  return {
    formDeliveryCancelMethods,
    loading,
    handleSubmit,
  };
};
