import { memo, useEffect, useState } from 'react';
import { Box, Tooltip } from '@mui/material';
import { useDispatch } from 'react-redux';
import '../../styles/pages/_filter.scss';
import {
  COLLECT_BUSINESS_IDS_FOR_HISTORY,
  COLLECT_BUSINESS_IDS_FOR_REPORT,
  IS_LOCATION_UNSELECT,
  SET_CURRENT_SELECT_LOCATIONS,
  SET_ALL_SELECTED_LOCATION,
  SET_SELECTED_LOCATIONS,
  ALL_LOCATIONS_DEPENDS_ON_BUSINESS_GROUP,
} from 'redux/actions/types';
import * as all from 'constants/permissions';
import usePermission from 'hooks/permission';
import { LabelName } from 'pages/Report/styles';
import { useTranslation } from 'react-i18next';
import dateIssue from 'assets/icons/dateIssue.svg';
import { MultiSelect } from 'react-multi-select-component';
import useLocationMultiSelect from 'hooks/useLocationMultiSelect';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

const LocationAutoCompleteReport = ({
  locationsList,
  currentSelectedLocations = [],
  isError = false,
  type = ''
}) => {
  const dispatch = useDispatch();
  const checkPermission = usePermission();
  const { t } = useTranslation();
  const {
    options,
    unSelect,
    sortOptions,
    selectedLocations,
    locationIdsForReport,
  } = useLocationMultiSelect(type);
  const [isErrorLocation, setIsErrorLocation] = useState(false);


  const setData = (option) => {
    const idsReport = [];
    if (unSelect && option.length > 1) {
      dispatch({ type: COLLECT_BUSINESS_IDS_FOR_REPORT, payload: [] });
      dispatch({ type: COLLECT_BUSINESS_IDS_FOR_HISTORY, payload: [] });
      dispatch({ type: IS_LOCATION_UNSELECT, payload: true });
      return;
    }
    option.forEach((item) => {
      if (item?.value) {
        return idsReport.push(item?.value);
      }
    });
    dispatch({ type: SET_ALL_SELECTED_LOCATION, payload: option });
    dispatch({ type: COLLECT_BUSINESS_IDS_FOR_REPORT, payload: idsReport });
  };

  const handleMultiSelectChange = (option) => {
    setIsErrorLocation(option.length === 0);
    dispatch({ type: SET_SELECTED_LOCATIONS, payload: option });
    setData(option);
    dispatch({ type: SET_CURRENT_SELECT_LOCATIONS, payload: option });
  };

  const customValueRenderer = (selected) => {
    if (selected?.length) {
      const name = selected[0]?.name;
      const extractName = name?.length > 18 ? `${name.slice(0, 18)}...` : name;
      if (selected?.length > 1) {
        return `${extractName} (+${selected?.length - 1} Other)`;
      } else return extractName;
    }
  };

  useEffect(() => {
    if (locationsList && locationsList?.length) {
      const isSuper = checkPermission(all['SUPER']);
      if (currentSelectedLocations.length > 0) {
        const ids = JSON.parse(localStorage.getItem('requestData'));
        const filterItems = currentSelectedLocations.filter((location) => {
          return ids?.locationIds.includes(location?.value);
        });
        dispatch({ type: SET_SELECTED_LOCATIONS, payload: isSuper ? [] : filterItems });
        isSuper ? setData([]) : setData(filterItems);
        return;
      }
      unSelect
        ? dispatch({ type: SET_SELECTED_LOCATIONS, payload: [] })
        : dispatch({ type: SET_SELECTED_LOCATIONS, payload: isSuper ? [] : locationsList });
      unSelect
        ? dispatch({ type: ALL_LOCATIONS_DEPENDS_ON_BUSINESS_GROUP, payload: [] })
        : dispatch({ type: ALL_LOCATIONS_DEPENDS_ON_BUSINESS_GROUP, payload: locationsList });
      unSelect || isSuper ? setData([]) : setData(locationsList);
    }
  }, [locationsList, unSelect]);

  useEffect(() => {
    setIsErrorLocation(locationIdsForReport?.length === 0);
  }, [locationIdsForReport]);

  return (
    <Box
      className={`${isError ? 'location-autocomplete-error' : ''}`}
      sx={{ width: '300px', marginTop: '20px', position: 'relative' }}
    >
      <LabelName
        sx={{
          color: isError ? '#E21508 !important' : '',
          span: {
            color: '#E21508 !important',
          },
        }}
      >
        All Locations <span>*</span>
      </LabelName>
      {(isError || isErrorLocation) && (
        <Tooltip title={t('help.field_is_required')} placement="top" arrow>
          <img
            width={16}
            height={16}
            alt="Date Issue Icon"
            src={dateIssue}
            style={{ top: '12px', position: 'absolute', zIndex: 1, left: '13.5px' }}
          />
        </Tooltip>
      )}
      <MultiSelect
        options={options}
        hasSelectAll={true}
        value={selectedLocations}
        debounceDuration={300}
        ArrowRenderer={() => <ArrowDropDownIcon color="action" />}
        valueRenderer={customValueRenderer}
        onChange={handleMultiSelectChange}
        labelledBy={'All Locations...'}
        onMenuToggle={(isOpen) => {
          !isOpen && sortOptions();
        }}
      />
    </Box>
  );
};
export default memo(LocationAutoCompleteReport);
