import { useEffect, useState, useRef } from 'react';
import { Box, TextField, Autocomplete } from '@mui/material';
import { companyTypesEnum } from 'constants/companyTypes';
import { useTranslation } from 'react-i18next';

const BusinessSelect = (props) => {
  const isFirstRender = useRef(true);
  const { businessList, brand, setIds } = props;
  const [businessName, handleBusinessName] = useState('');
  const { t } = useTranslation();

  useEffect(() => {
    const currentBusiness = JSON.parse(localStorage.getItem('businessInfo'));
    if (businessList) {
      const filterBusinessByBrand = businessList.find((item) => {
        return item.type === companyTypesEnum.BRAND && item.brandId === brand?._id;
      });
      if (filterBusinessByBrand) {
        handleBusinessName(filterBusinessByBrand?.label);
        setIds(filterBusinessByBrand.id);
      } else {
        const filterBusinessById = businessList.find((item) => {
          return item.brandId === brand?._id;
        });
        if (filterBusinessById) {
          handleBusinessName(filterBusinessById?.label);
          setIds(filterBusinessById.id);
        } else if (isFirstRender.current) {
          handleBusinessName(currentBusiness?.label);
          setIds(currentBusiness?.id);
        }
      }
    }
    isFirstRender.current = false;
  }, [businessList, brand]);

  const onChangeBusiness = (_, businessData) => {
    if (!businessData) return;
    handleBusinessName(businessData?.label);
    setIds(businessData?.id);
  };
  return (
    <Autocomplete
      renderOption={(props, option) => {
        return (
          <>
            <Box {...props} component="li" sx={{ p: 1 }} key={option.id}>
              {option.label}
            </Box>
          </>
        );
      }}
      disablePortal={false}
      options={businessList}
      value={businessName}
      renderInput={(params) => {
        return <TextField {...params} name="businessId" label={t('add_location.businesses')} size="small" />;
      }}
      onChange={onChangeBusiness}
    />
  );
};

export default BusinessSelect;
