import styled from '@emotion/styled';
import { Box, Card, CardContent, Typography, Button } from '@mui/material';

export const BusinessSettingsWrapper = styled(Box)({
  maxWidth: '998px',
});

export const BusinessSettingsCard = styled(Card)({
  width: '100%',
});

export const BusinessSettingsCardContent = styled(CardContent)(({ theme }) => ({
  padding: theme.spacing(3, 3, 2, 3),
  '&:last-child': {
    paddingBottom: theme.spacing(1.5),
  },
  boxShadow: ' 0px 2px 6px 2px rgba(0, 0, 0, 0.15) 0px 1px 2px 0px rgba(0, 0, 0, 0.30)',
}));

export const BusinessLogoWrapper = styled(Box)({});

export const LogoTitle = styled(Typography)(({ theme }) => ({
  color: '#929398',
  fontSize: '14px',
  fontWeight: '400',
  lineHeight: '24px',
  letterSpacing: '0.5px',
  paddingBottom: '8px',
  margin: theme.spacing(0),
}));

export const BusinessQrWrapper = styled(Box)({
  height: '88px',
  width: '88px',
  '& > img': {
    width: '100%',
    height: '100%',
  },
});

export const BusinessQrMain = styled(Box)(({ theme }) => ({
  margin: theme.spacing(3),
}));

export const BusinessQrImageButtonWrapper = styled(Box)({
  display: 'flex',
  gap: '16px',
});

export const DownloadWrapper = styled(Box)({
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
});

export const DownloadButton = styled(Button)(({ theme }) => ({
  color: theme.palette.secondary.main,
  borderRadius: '6px',
  border: ' 1px solid rgba(98, 106, 122, 0.50)',
}));

export const DownloadCopyButton = styled(Button)(({ theme }) => ({
  color: theme.palette.secondary.main,
  borderRadius: '6px',
  border: ' 1px solid rgba(98, 106, 122, 0.50)',
  marginTop: '16px',
}));

export const DownloadCopyButtonTitle = styled(Typography)({
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  maxWidth: '250px',
  fontWeight: 500,
  fontSize: '0.875rem',
});

export const CopiedMessage = styled(Box)(({ theme }) => ({
  position: 'absolute',
  bottom: '-42px',
  marginTop: '8px',
  background: theme.palette.secondary.main,
  padding: '4px 12px',
  borderRadius: '6px',
}));

export const CopiedMessageWebUrl = styled(Box)(({ theme }) => ({
  position: 'absolute',
  bottom: '20px',
  right: '16px',
  marginTop: '8px',
  background: theme.palette.secondary.main,
  padding: '4px 12px',
  borderRadius: '6px',
}));

export const CommEmailHelperMessage = styled(Typography)({
  fontSize: '12px',
  fontWeight: 400,
  color: '#929398',
  paddingTop: '4px',
  marginLeft: '16px',
});

export const BlocksWithoutSeparator = styled(Box)(({ theme }) => ({
  margin: theme.spacing(2, 3, 3, 3),
}));
