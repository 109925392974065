import * as yup from 'yup';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { yupResolver } from '@hookform/resolvers/yup';
import useProviderConfig from 'hooks/useProviderConfig';
import { selectCurrentBusiness } from 'redux/selectors';
import { addManualDoordashUpdate } from 'redux/actions/Menu/menuV2';

export const manualDoordashUpdateSchema = yup.object().shape({
  manualUpdate: yup.boolean(),
});

const useManualDoordashMenuUpdate = () => {
  const dispatch  = useDispatch();
  const { providers } = useProviderConfig();
  const [loading, setLoading] = useState(false);
  const { menu } = useSelector(({ menu }) => menu);
  const currentBusiness = useSelector(selectCurrentBusiness);  

  const manualDoordashUpdate = useForm({
    defaultValues: { manualUpdate: false },
    mode: 'all',
    resolver: yupResolver(manualDoordashUpdateSchema),
  });

  useEffect(() => {
   if (menu.providersSettings) {
    manualDoordashUpdate.reset({
      manualUpdate: menu.providersSettings[providers?.doordash?.srv]?.manualUpdate
    })
   }
  },[menu?.providersSettings[providers?.doordash?.srv]?.manualUpdate]) 

  const handleFormSubmit = (values) => {
    setLoading(true);
    const manualFlowData = {
      manualUpdate: values.manualUpdate,
      provider: providers?.doordash?.srv,
      restaurantMailbox: currentBusiness?.restaurantMailbox,
      menuId: menu?._id
    };
    dispatch(addManualDoordashUpdate(manualFlowData)).then(() => {
      setLoading(false);
      manualDoordashUpdate.reset({
        manualUpdate: values.manualUpdate
      }, { keepDirty: false, keepTouched: false })
    })
    .finally(() => setLoading(false))
  }

  return {
    manualDoordashUpdate,
    handleFormSubmit,
    loading
  }
}

export default useManualDoordashMenuUpdate