import { useEffect } from 'react';
import { useFieldArray } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import {
  FormControlLabel,
  Grid,
  InputAdornment,
  Switch,
  Tooltip,
} from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import LabelRequired from 'components/FormFields/LabelRequired';
import CustomTextInput from 'components/Forms/CustomTextInput';
import ActionsFlat from './ActionsFlat';
import LimitProviderFee from './LimitProviderFee';
import { MainContainer, MainRows } from './styles';
import { priceTransform } from 'helpers/formPrice';
import { DEFAULT_DELIVERY_RATE_SETTINGS } from 'constants/website';
import DeliveryLimits from 'pages/WebsiteSettings/DeliveryLimits/DeliveryLimits';

const FlatFee = ({ websiteFormMethods, model }) => {
  const { t } = useTranslation();
  const { currentBusiness } = useSelector(({ business }) => business);
  const { register, control, watch, formState, reset, getValues } =
    websiteFormMethods;
  const websiteSettings =
    model?.deliveryFlatFeeSettings?.deliveryRateSettings || [];
  const isHouseDelivery =
    getValues('deliveryBy')?.[0]?.deliveryService === 'self';
  const flatEnable = isHouseDelivery
    ? isHouseDelivery
    : watch('deliveryFlatFeeSettings.flatFeeEnabled');
  const flatFeeFields = useFieldArray({
    control,
    name: 'deliveryFlatFeeSettings.deliveryRateSettings',
  });

  const { fields } = flatFeeFields;

  const showErrors = (name, index) => {
    const err =
      formState.errors?.deliveryFlatFeeSettings?.deliveryRateSettings[index]?.[
        name
      ];
    return t(err?.message);
  };

  useEffect(() => {
    if (flatEnable && !flatFeeFields.fields?.length) {
      flatFeeFields.append(DEFAULT_DELIVERY_RATE_SETTINGS);
    }
  }, [flatEnable, flatFeeFields]);

  useEffect(() => {
    if (!flatEnable && currentBusiness) {
      reset({
        ...websiteFormMethods.getValues(),
        deliveryFlatFeeSettings: {
          flatFeeEnabled: flatEnable || false,
          deliveryRateSettings: websiteSettings,
        },
      });
    }
  }, [reset, flatEnable]);

  const handleChange = (event) => {
    const value = priceTransform(event.target.value);
    if (event.target.name?.includes('providerLimitedFee')) {
      websiteFormMethods.clearErrors(event.target.name);
    }
    websiteFormMethods.setValue(event.target.name, value, {
      shouldDirty: true,
    });
  };

  useEffect(() => {
    websiteFormMethods.setValue(
      'deliveryLimits.maxDeliveryRadius',
      model.deliveryLimits?.maxDeliveryRadius
    );
    websiteFormMethods.setValue(
      'deliveryLimits.maxDeliveryFee',
      model.deliveryLimits?.maxDeliveryFee
    );
  }, [flatEnable]);

  return (
    <MainContainer>
      <FormControlLabel
        sx={{ width: 'max-content', mb: 3 }}
        control={
          <Switch
            disabled={isHouseDelivery}
            checked={flatEnable}
            {...register('deliveryFlatFeeSettings.flatFeeEnabled')}
          />
        }
        label={t('flat_fee.enable_flat_fee')}
        name="deliveryFlatFeeSettings.flatFeeEnabled"
      />
      <Grid container>
        {flatEnable ? (
          fields?.map((flat_item, index) => {
            const limitProviderFeeEnable = watch(
              `deliveryFlatFeeSettings.deliveryRateSettings[${index}].providerLimitFeeEnabled`
            );
            return (
              <MainRows key={index}>
                <Grid item>
                  <CustomTextInput
                    name={`deliveryFlatFeeSettings.deliveryRateSettings[${index}].deliveryRadiusFrom`}
                    label={
                      <LabelRequired>{t('flat_fee.radius_from')}</LabelRequired>
                    }
                    InputProps={{
                      startAdornment: showErrors(
                        'deliveryRadiusFrom',
                        index
                      ) && (
                        <Tooltip
                          title={showErrors('deliveryRadiusFrom', index)}
                          placement="top"
                          arrow
                        >
                          <InfoIcon
                            sx={{ color: '#d32f2f', fontSize: '21px' }}
                          />
                        </Tooltip>
                      ),
                      endAdornment: (
                        <InputAdornment position="end">
                          <Tooltip title={t('flat_fee.delivery_radius_from')}>
                            <HelpOutlineIcon fontSize={'small'} />
                          </Tooltip>
                        </InputAdornment>
                      ),
                    }}
                    sx={{ width: '150px' }}
                    inputProps={{ type: 'number' }}
                  />
                </Grid>
                <Grid item>
                  <CustomTextInput
                    name={`deliveryFlatFeeSettings.deliveryRateSettings[${index}].deliveryRadiusTo`}
                    label={
                      <LabelRequired>{t('flat_fee.radius_to')}</LabelRequired>
                    }
                    InputProps={{
                      startAdornment: showErrors('deliveryRadiusTo', index) && (
                        <Tooltip
                          title={showErrors('deliveryRadiusTo', index)}
                          placement="top"
                          arrow
                        >
                          <InfoIcon
                            sx={{ color: '#d32f2f', fontSize: '21px' }}
                          />
                        </Tooltip>
                      ),
                      endAdornment: (
                        <InputAdornment position="end">
                          <Tooltip title={t('flat_fee.delivery_radius_to')}>
                            <HelpOutlineIcon fontSize={'small'} />
                          </Tooltip>
                        </InputAdornment>
                      ),
                    }}
                    inputProps={{ type: 'number' }}
                    sx={{ width: '150px' }}
                  />
                </Grid>
                <Grid item>
                  <CustomTextInput
                    name={`deliveryFlatFeeSettings.deliveryRateSettings[${index}].deliveryPrice`}
                    label={
                      <LabelRequired>
                        {t('flat_fee.delivery_price')}
                      </LabelRequired>
                    }
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">$</InputAdornment>
                      ),
                      endAdornment: (
                        <InputAdornment position="end">
                          <Tooltip
                            title={t(
                              'flat_fee.specify_the_delivery_fee_within_the_defined_range'
                            )}
                          >
                            <HelpOutlineIcon fontSize={'small'} />
                          </Tooltip>
                        </InputAdornment>
                      ),
                    }}
                    onChange={handleChange}
                    sx={{ width: '150px' }}
                    inputProps={{ type: 'number' }}
                  />
                </Grid>
                <ActionsFlat
                  flatFeeFields={flatFeeFields}
                  indexItem={index}
                  id={flat_item?.id}
                />
                <LimitProviderFee
                  showErrors={showErrors}
                  handleChangeLimitProvider={handleChange}
                  limitProviderFeeEnable={limitProviderFeeEnable}
                  websiteFormMethods={websiteFormMethods}
                  itemIndex={index}
                />
              </MainRows>
            );
          })
        ) : (
          <></>
        )}
      </Grid>
      {!websiteFormMethods.getValues(
        'deliveryFlatFeeSettings.flatFeeEnabled'
      ) && !isHouseDelivery ? (
        <Grid item xs={12}>
          <DeliveryLimits websiteFormMethods={websiteFormMethods} />
        </Grid>
      ) : (
        <></>
      )}
    </MainContainer>
  );
};

export default FlatFee;
