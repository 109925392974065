import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { sortBySelectedValues } from 'utils/extractIds';
import { selectAllBrandsDependsOnBusinessGroup, selectSelectedBrands } from 'redux/selectors';


const useBrandMultiSelect = (type) => {
  const [options, setOptions] = useState([]);

  const selectedBrands = useSelector(selectSelectedBrands);
  const allBrandsDependsOnBusinessGroup = useSelector(selectAllBrandsDependsOnBusinessGroup);

  const sortOptions = () => {
    const sortedBrandOptions = sortBySelectedValues(selectedBrands, allBrandsDependsOnBusinessGroup);
    setOptions(sortedBrandOptions);
  };

  useEffect(() => {
    if (allBrandsDependsOnBusinessGroup?.length) {
      setOptions(allBrandsDependsOnBusinessGroup);
      if (type) {
        sortOptions();
      }
    }
  },[allBrandsDependsOnBusinessGroup?.length, type])

  return {
    options,
    sortOptions,
    selectedBrands,
  }
}

export default useBrandMultiSelect;