import { useEffect, useState } from 'react';

const usePrepTimeModal = (watch, businessFormMethods, serverPrepTime) => {
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const defaultPrepTimeWatcher = watch('defaultPrepTime');

  const handleCloseConfirmModal = () => {
    setOpenConfirmModal(false);
    businessFormMethods.setValue('defaultPrepTime', serverPrepTime, { keepDirty: false });
  };

  const handleConfirm = () => setOpenConfirmModal(false);

  useEffect(() => {
    const isNotEqual = businessFormMethods.getValues()?.defaultPrepTime !== serverPrepTime;
    const fieldIsDirty = Object.prototype.hasOwnProperty.call(
      businessFormMethods.formState.dirtyFields, 'defaultPrepTime'
    );
    const isDataAvailable = serverPrepTime &&
      Object.keys(businessFormMethods.getValues()).length > 0 && fieldIsDirty;
    if (isNotEqual && isDataAvailable) {
      setOpenConfirmModal(true);
    }
  },[defaultPrepTimeWatcher])

  return {
    openConfirmModal,
    handleCloseConfirmModal,
    handleConfirm,
  }
}
export default usePrepTimeModal;