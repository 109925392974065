import { Box } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useTranslation } from 'react-i18next';
import { FormProvider } from 'react-hook-form';
import CustomCheckbox from 'components/Forms/CustomCheckbox';
import useManualDoordashMenuUpdate from 'pages/MenuNew/MenuLinkDrawer/hooks/useManualDoordashMenuUpdate';
import { ManualDoordashBox, ManualDoordashContent, ManualDoordashWrapper } from 'pages/MenuNew/MenuLinkDrawer/styles';

const DoordashManualUpdate = () => {
  const { t } = useTranslation();

  const {
    manualDoordashUpdate,
    handleFormSubmit,
    loading
  } = useManualDoordashMenuUpdate()

  return (
    <ManualDoordashWrapper>
      <ManualDoordashBox>
        <ManualDoordashContent>
          <FormProvider {...manualDoordashUpdate}>
            <form onSubmit={manualDoordashUpdate.handleSubmit(handleFormSubmit)}>
              <Box 
              sx={{ 
                   textAlign: 'end',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'end'
               }}>
                <CustomCheckbox label={t('Manual Menu Update')} name="manualUpdate" />
                <LoadingButton
                  variant="text"
                  color="primary"
                  type="submit"
                  loading={loading}
                  disabled={!manualDoordashUpdate.formState.isDirty || loading}>
                  {t('settings.save')}
                </LoadingButton>
              </Box>
            </form>
          </FormProvider>
        </ManualDoordashContent>
      </ManualDoordashBox>
    </ManualDoordashWrapper>
  )
}

export default DoordashManualUpdate;